/* Import css variables */
@import "../../../variables.styles.css";


section .table_size {
    width: 95%;   
    /* margin-left: 1%; */
}
section .table_size tbody tr td{
    font-size: .89rem;
}
section .table_size tbody tr td .view_icon {
    width: 22px;
    height: 22px;
}
section .table_size tbody tr td .action_icons .edit_icon {
    width: 10px!important;
    height: 10px!important;
    cursor: pointer;
}
section .table_size tbody tr td .action_icons .delete_icon {
    width: 22px;
    height: 22px;
    cursor: pointer!important;
}
.bell_icon {
    width: 22px;
    height: 22px;
    cursor: pointer!important;
    color: #020079 !important;
}
.title-cus img {
    cursor: pointer;
}
form .job_form {
    height: auto;
    margin-top: .5rem;
}
form .job_form textarea{
    width: 98%;
    height: 6rem;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    border-radius: 15px; 
    padding-left: 1rem;
    outline: none;
}
form .job_form select{
    width: 98%;
    height: 48px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    border-radius: 25px; 
    padding-left: 1rem;
    outline: none;
}
form .update_job_button{
    width: 20%;
}
/* Import css variables */
@import "../../variables.styles.css";

div button[type="submit"]{
    width: 196px;
    height: 48px;
    left: 727px;
    top: 714px;
    background: #020079;
    /* Button/primary with effect */
    
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 25px;
    font-family: VAR(--font-family);
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    border: none;
    margin-top: 20px;
}

/* MOBILE RESPONSIVENESS */
@media(max-width: 500px){
    div button[type="submit"]{
        width: 100%;
    }
}
@media (max-width: 900px){
    div button[type="submit"]{
        width: 90%;
    }
}
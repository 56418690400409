/* Import css variables */
@import "../../../../variables.styles.css";

.Projects_wrapper h3{
    color: var(--deepBlue-background);
    margin-left: 2rem;
    margin-top: 2rem;
}

.Projects_wrapper .projects_button
    button[type="button"] {
    color: #FFFFFF;
    border-radius: 25px;
    border: none;
    font-size: .87rem;
    margin-top: 2.5%;
}

.Projects_wrapper .projects_button .projects_button--back{
    width: 6rem;
    height: 40px;
    background: var(--lightBlue);
    margin-left: 1.9rem;
}

.Projects_wrapper .projects_button .projects_button--add {
    width: 7.5rem;
    height: 42px;
    background: var(--deepBlue-background);
    margin-right: 1.9rem;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.notification{
    
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
 .notification__table{
    width: 95%;
    border-spacing: 0px;
    table-layout: fixed;
    margin-left: auto;
    margin-right: auto;
    border-collapse: collapse;
 }
 .notification__table thead{
    background-color: rgba(140, 139, 190, 0.65) !important;
    color: #495057 !important;

 }
 .notification__table td{
    padding: 1rem 0.3rem;
    word-wrap: break-word;
 }
 
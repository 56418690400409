.submitted {
  background-color: rgb(252, 250, 250);
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  margin: 1rem 0;
  position: relative;
}

.submitted__left {
  width: 70%;
}
.submitted__right {
  /* background-color: #fff; */
  width: 28%;
  position: relative;
  /* position: sticky; */
  /* position: fixed;
    right: 0; */
}

.submitted__left--task {
  background-color: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 1rem;
  border-radius: 5px;
  border-left: 5px solid rgb(251, 255, 0);
}
.completed__left--task {
  background-color: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 1rem;
  border-radius: 5px;
  border-left: 5px solid rgb(31, 218, 93);
}
.rejct__left--task {
  background-color: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 1rem;
  border-radius: 5px;
  border-left: 5px solid rgb(218, 31, 31);
}
.rjct-table {
  border-left: 105px solid rgb(218, 31, 31);
  background-color: rgb(247, 144, 144);
  color: #fff !important;
}
.sent-offer__left--task {
  background-color: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 1rem;
  border-radius: 5px;
  border-left: 5px solid rgb(138, 135, 135);
}
.submitted__right--order {
  background-color: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 1rem;
  border-radius: 5px;
  position: sticky;
  top: 0;
  /* position: fixed; */
  width: 100%;
}

.delivery,
.order,
.total,
.submitted__action {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.2rem;
}
/* .submitted__details{
    background-color: #fff;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 1rem ;
    border-radius: 5px;
    margin: 1rem 0;
} */

.submitted__table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #ddd;
}
.submitted__details,
.delivered__details {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 1rem;
  border-radius: 5px;
  margin: 1rem 0;
}
.delivered__details--left {
  width: 8%;
}
.delivered__details--right {
  width: 90%;
}
.details-icon {
  background: rgb(233, 231, 231);
  border-radius: 100%;
  padding: 0.5rem;
  margin-right: 0.5rem;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
svg {
  font-size: 1.5rem;
}

thead {
  background-color: rgb(238, 237, 237);
}

th {
  padding: 0.8rem 0.3rem;
}
.tr-body {
  padding: 1rem 0.3rem;
}
.tr-foot {
  background-color: rgb(238, 237, 237);
  padding: 0.5rem 0.3rem;
}

/* delivery section */

.delivery__sub {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.delivery__sub-left {
  width: 8%;
}
.delivery__sub-right {
  width: 90%;
}
.delivery__user {
  font-size: 2.2rem;
}
.delivered__attachment {
  display: flex;
  justify-content: space-between;
}

.delivered__attached {
  border: 1px solid rgb(238, 237, 237);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.attached-icon {
  padding: 1rem;
}
.attachment__download {
  border-top: 1px solid rgb(238, 237, 237);
  padding: 0.5rem;
}

.submitted__action {
  margin: 1rem 0;
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
}

.submitted__action--accept,
.submitted__action--decline {
  padding: 0.75rem 1.2rem;
  font-size: 1rem;
  font-weight: 500;
  width: 100%;
  color: #fff;
  outline: none;
  border: none;
  background-color: rgb(4, 155, 4);
}
.button__action--wrapper {
  width: 48% !important;
  height: 2rem !important;
}
.submitted__action--decline {
  background-color: rgb(189, 4, 4);
}

.date__ordered {
  padding: 0 0.9rem;
  border-left: 2px solid grey;
}
.ordered__talent {
  padding: 0 0.9rem 0 0;
}

/* Import css variables */
@import "../../../variables.styles.css";

/* HERO SECTION */
.subhomenav_hr{
    width: 100% !important;
}
section .hero_section {
    width: 100%;
    background: var(--hero-section-color);
    display: grid;
    grid-template-columns: 60% 40%;
}
section .hero_section .hero_section--left {
    margin-top: 8rem;
    margin-left: 10%;
}
section .hero_section .hero_section--left h1{
    max-width: 654px;
    /* height: 116px;    */
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 60px;
    color: #FFFFFF;
    animation-name: moveToLeft;
    animation-duration: 2s;
}
section .hero_section .hero_section--left p {
    max-width: 615px;
    font-style: normal;
    font-weight: 500;
    font-size: 19;
    line-height: 32px;
    color: #FFFFFF;
    animation-name: moveToRight;
    animation-duration: 2s;   
}
section .hero_section .hero_section--left 
.hero_section--left-inputSearch {
    display: flex;
    flex-direction: column;
    left: -13%;
    margin-left: 0 !important;
    transition: 0.5s;
    animation-name: animateBtn;
    animation-duration: 1s;
    animation-delay: 1.5s;     
    animation-fill-mode: backwards; 
}
.home_searchhome{
    background-color: white;
    margin-left: 0;
    width: 550px;
    margin-top: 20px;
    border-radius: 25px;
    padding: 3px 0;
    display: flex;
    margin: 0 0 3em 0;
}
.home_searchinput{
    width: 380px;
    margin-left: 15px;
    margin-right: 5px;
    border: none;
    outline: none;
    height: 23px;
    margin-top: 10px;
}
 .search_but{
    margin-top: 0px !important;
    left: 53%!important;
    top: -4.2px!important;
    width: 146px!important;
    height: 44px!important;
    background: #020079!important;
    border-radius: 25px!important;
    font-style: normal!important;
    font-weight: 600!important;
    font-size: .86rem!important;
    line-height: 24px!important;
    color: #FFFFFF!important;
    border: none!important;
    outline: none!important;
}
section .hero_section .hero_section--left 
.hero_section--left-inputSearch .hero_search{
    width: 70%;
    height: 48px !important;
    background: #FFFFFF;
    border-radius: 25px;
    margin-left: 0;
    margin-top: 2%;
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.16);
    padding-left: 2rem;

}

/* RIGHT SECTION */
section .hero_section .hero_section--right {
    margin-top: 1.3rem;
}

section .hero_section .hero_section--right img{
    width: 100%;
}
.search_butholder{
    width: 30%;
}
/* LOGO SLIDE SECTION */
.wrapper_carousel {
    width: 100%;
    height: auto;
    background: #ADD8E6;
    padding-top: 1rem;
    position: relative;
}

.wrapper_carousel h5{
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    font-feature-settings: 'liga' off, 'calt' off;
    /* color: rgba(0, 0, 0, 0.8); */
    color: var(--deepBlue-background);
    position: absolute;
    left: 5%;
}
.auto_carouselSlide {
    width: 100%;
    height: auto;
    padding-top: 1.8rem;
    padding-bottom: 1.8rem;
    background: #ADD8E6;
}

.auto_carousel .visa {
    width: 18%;
    height: 35px;
}
.search_buticon{
    display: none;
}
.auto_carousel .visa img {
    width: 100%;
}

/* CSS ANIMATION */
@keyframes moveToLeft {
    0% {
        transform: translateX(120px);
        opacity: 0;
    }
    100%{
        transform: translateX(0);
        opacity: 1;
    }
}
@keyframes moveToRight {
    0% {
        transform: translateX(-120px);
        opacity: 0;
    }
    100%{
        transform: translateX(0);
        opacity: 1;
    }
}
@keyframes animateBtn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media (max-width: 830px) {
    section .hero_section .hero_section--left 
.hero_section--left-inputSearch .search_but{
    left: 38% !important;
    top: -10px !important;
    margin-bottom: 10px;
}
section .hero_section .hero_section--right {
    display: none;
}
section .hero_section {
    width: 100%;
    background: var(--hero-section-color);
    display: grid;
    grid-template-columns: 90% 10%;
}
.home_searchhome{
    width: 100%;
}

.home_searchinput{
    width: 70%;
    /* margin-right: 25px; */
}
/* .search_but{
    width: 100% !important;
} */
/* section .hero_section .hero_section--right {
    margin-top: 1.3rem;
    height: 400px;
} */
section .hero_section .hero_section--right img{
    width: 3rem;
    height: 400px;
}
section .hero_section .hero_section--left 
.hero_section--left-inputSearch .hero_search{
    width: 110%;
}
section .hero_section .hero_section--left 
.hero_section--left-inputSearch .search_but{
    left: 78% !important;
}
section .hero_section {
    min-height: 50vh;
}
}
/* @media (max-width: 785px){
    .home_searchinput{
        width: 300px;
        margin-right: 25px;
    }
    .home_searchhome {
        width: 90%;
    }
} */
@media (max-width: 430px) {
    section .hero_section {
        min-height: 100vh;
    }
    section .hero_section .hero_section--left 
.hero_section--left-inputSearch .hero_search{
    position: absolute;
    width: 175%;
}
section .hero_section .hero_section--left 
.hero_section--left-inputSearch .search_but{
    left: 105% !important;
    top: -14px !important;
}
}
@media (max-width: 600px) {
    section .hero_section {
        min-height: 50vh;
    }
    .search_buticon{
        display: block;
        width: 100% !important;
        margin-top: 0!important;
        padding: 10px 20px;
    }
    .home_searchinput{
        width: 80%;
        
    }
    .search_but{
        display: none;
    }
    .home_searchhome{
       margin-bottom: 10px;
    }
    section .hero_section .hero_section--left 
.hero_section--left-inputSearch .hero_search{
    position: absolute;
    width: 165%;
}
section .hero_section .hero_section--left 
.hero_section--left-inputSearch .search_but{
    left: 95% !important;
    top: -14px !important;
}
section .hero_section .hero_section--left h1{
    padding-left: 15px;
}
section .hero_section .hero_section--left p {
    padding-left: 15px;
}
}
@media (max-width: 600px) {
    section .hero_section .hero_section--left h1{
        font-size: 30px;
    }
}
/* Import css variables */
@import "../../../../../variables.styles.css";

.scope-heading{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}
.scope-heading .left-subheading {
    margin-left: 4.1%;
}

/* TOGGLE STYLES */
.scope-heading .right-subheading{
    display: flex;
    flex-flow: row wrap;
    /* align-items: center; */
    margin-right: 4.1%;
    margin-top: 4%;
}
section .scope-heading .right-subheading 
.toggle{
    margin-right: 1rem!important;
    color: var(--deepBlue-background);
    font-family: var(--font-family);
    font-weight: 550;
    margin-top: .2rem;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--deepBlue-background);
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }

  input[type="checked"]{
      color: #F1C40F;
      background: #F1C40F;
  }
  
  input:checked + .slider {
    background-color: var(--deepBlue-background);
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px var(--deepBlue-background);
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
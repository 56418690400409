/* Import css variables */
@import "../../../../variables.styles.css";

.table_tracker {
    width: 93%;
    margin-left: 4%;
}

 .view_icon_tracker {
    width: 24px;
    height: 24px;
    color: var(--deepBlue-background);
}
/* Import css variables */
@import "../../../variables.styles.css";


.searchgig_result h3 {
    margin-left: 6.5%;
    margin-top: 8%;
}

.searchgig_result h3 span {
    color: var(--deepBlue-background);
}

.searchgig_result .card_row {
    padding-bottom: 1rem;
}

.searchgig_result .card_row .card_row--main{
    padding-left: 0;
    padding-right: 0;
    margin-top: 5%;
    width: 21%;
    height: 52vh;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
    cursor: pointer;
}
.searchgig_result .card_row .card_row--main img {
    width: 100%!important;
    height: 150px;
    object-fit: cover;
}
.searchgig_result .card_row .card_row--main
 .profile_talent {
     /* width: 100%; */
     margin-top: 1rem;
 }
 .searchgig_result .card_row .card_row--main
 .profile_talent p {
    font-style: normal;
    font-weight: 800;
    font-size: .89rem;
    line-height: 12px;
    color: rgba(0, 0, 0, 0.8);
    margin-left: .8rem;
 }
.searchgig_result .card_row .card_row--main
 .profile_talent img{
    width: 24px;
    height: 24px;    
 }
 .searchgig_result .card_row .card_row--main
 .gig_copy {
     margin-top: 2rem;
 }
 .searchgig_result .card_row .card_row--main
 .gig_copy h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 24px;    
    color: rgba(0, 0, 0, 0.8);    
    margin-left: .8rem;
    /* padding-left: .2rem;
    padding-right: .2rem; */
 }
 .searchgig_result .card_row .card_row--main
 .gig_copy .start_price p{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;    
    color: #000000;
 }
 .searchgig_result .card_row .card_row--main
 .gig_copy .start_price {
     display: flex;
     justify-content: flex-end;
     padding-right: 1rem;
     width: 100%;
     height: 10rem;
     margin-top: -.5rem;
 }
 
 .searchgig_result .card_row .card_row--main
 .gig_copy .start_price p span {
    font-size: 1rem;
    font-weight: 600;
 }
 .searchgig_result .card_row .card_row--main
 .gig_copy .gig_line {
     width: 100%;
     /* margin-right: 0!important; */
     margin-left: 0!important;
     margin-top: 2rem;
 }
 .searchgig_result .card_row .card_row--main
 .gig_copy .rating_result img{
     width: 20px!important;
     height: 20px;
     /* z-index: 5; */
 }
  
 .searchgig_result .card_row .card_row--main
 .gig_copy .rating_result {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
    margin-right: 1rem;
 }
 .searchgig_result .card_row .card_row--main
 .gig_copy .rating_result p{
     font-size: .9rem;
 }
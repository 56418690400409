.pitch_text_wrapper{
    width: 80% !important;
    margin: auto;
    padding: 1.0rem;
    border: solid 1px #ccc;
    border-radius: 8px;
}

.text-cover{
    border:  2px solid rgba(140, 139, 190, 0.65);
    min-height: 15rem;
}
/* Import css variables */
@import "../../../variables.styles.css";

/* THE LEFT LAYOUT STYLE */
.signup-wrapper .signup-wrapper-child-1 .left-signup{
    /* height: 200vh; */
    background: var(--deepBlue-background);
}
.signup_name{
    width: 55%;
    height: 48px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    border-radius: 25px;
    padding-left: 1rem;
    outline: none;
}
.signup-wrapper .signup-wrapper-child-1 .left-signup h1{
    /* width: 365px;
    height: 118px; */
    font-family: var(--font-family);
    font-style: normal;
    font-weight: bold;
    font-size: 1.8rem;
    line-height: 60px;
    font-feature-settings: 'liga' off;
    color: #FFFFFF;
}

.signup-wrapper .signup-wrapper-child-1 .left-signup div{
   margin-left: 10%;
   margin-top: 16%;
}

.signup-wrapper .signup-wrapper-child-1 .left-signup div img{
    /* width: 447px; */
    /* height: 223.29px; */
    margin-top: 8%;
    margin-right: 2%;
}

/* THE RIGHT LAYOUT STYLE */
.signup-wrapper .signup-wrapper-child-1 .right-signup {
    padding-top: 6%;
}
.signup-wrapper .signup-wrapper-child-1 .right-signup h1{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 60px;
    font-feature-settings: 'liga' off;
    color: #020079;
    margin-left: 8%;
    /* margin-top: 2rem; */
}

.signup-wrapper .signup-wrapper-child-1 .right-signup h6{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    font-feature-settings: 'liga' off, 'calt' off;
    color: rgba(2, 0, 121, 0.65);
    margin-left: 8%;
}

.signup-wrapper .signup-wrapper-child-1 .right-signup .social-buttons{
    margin-top: -1%;
    margin-left: 8%;
}

.signup-wrapper .signup-wrapper-child-1 .right-signup .social-buttons button[type="button"]{
    width: 228px;
    height: 55px;
    background: #FFFFFF;
    border: 1px solid #020079;
    box-sizing: border-box;
    border-radius: 30px;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #020079;
    margin-top: 5%;
}
.signup-wrapper .signup-wrapper-child-1 .right-signup .social-buttons button[type="button"] img{
    margin-right: .5rem;
}
.signup-wrapper .signup-wrapper-child-1 .right-signup .social-buttons button[type="button"]:nth-child(2){
    margin-left: 1rem;
}

.signup-wrapper .signup-wrapper-child-1 .right-signup .line{
    display: flex;
    flex-flow: row wrap;
    margin-left: 8%;
    align-items: center;
    margin-top: 24px;
}
.signup-wrapper .signup-wrapper-child-1 .right-signup .line hr{
    width: 234px;
    height: 1px;
}

/* THE FORM */
.signup-wrapper .signup-wrapper-child-1 .right-signup form{
    margin-left: 8%;
}
.signup-wrapper .signup-wrapper-child-1 .right-signup p{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.8);
}
.signup-wrapper .signup-wrapper-child-1 .right-signup p{
    margin-left: 8%;
    margin-top: 1rem;
}
.signup-wrapper .signup-wrapper-child-1 .right-signup p span{
    color: #020079!important;
    text-decoration: underline;
}
.invalid-entry{
    color: red !important;
    font-size: 12px;
}

/* MOBILE RESPONSIVENESS */
@media(max-width: 500px){
    .signup-wrapper .signup-wrapper-child-1 .left-signup {
        display: none;
    }
    .signup-wrapper .signup-wrapper-child-1 .right-signup
    .mobile-center,
    .signup-wrapper .signup-wrapper-child-1 .right-signup 
    h1,
    .signup-wrapper .signup-wrapper-child-1 .right-signup
     h6{
        text-align: center;
        margin-left: 0;
    }
    .signup-wrapper .signup-wrapper-child-1 .right-signup 
    .social-buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .signup-wrapper .signup-wrapper-child-1 .right-signup 
    .social-buttons button[type="button"]:nth-child(2){
        margin-left: 0;
    }
    .signup-wrapper .signup-wrapper-child-1 .right-signup 
    .line {
        flex-direction: column;
        margin-left: 0;
    }
    .signup-wrapper .signup-wrapper-child-1 .right-signup
     form {
         margin-left: 0;
     }
 }
/* @media (max-width: 900) {
    .signup_name{
        width:100% !important;
    }
} */
/* Import css variables */
@import "../../../variables.styles.css";

.preview-story button[type="button"]{
    width: 90%;
    height: 48px;
    background: #020079;
    border-radius: 25px;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    border: none;
    margin-top: 1rem;
}
/* .Modal-cus{
    width: 150vw!important;
    right: 50%!important;
    left: -50%!important;
} */
.modal .title-cus img{
    width: 32px;
    height: 32px;
    cursor: pointer;
    float: right;
    margin: 2% 2% 0 0;
}
.modal .title-cus h3{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;    
    font-feature-settings: 'liga' off, 'calt' off;
    color: #020079;
    text-align: center;
    margin-top: 10%;
    /* margin-right: -2%; */
}
.modal .title-cus h6{
    /* max-width: 405px; */
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: rgba(2, 0, 121, 0.65);
    text-align: center;
}
.location{
   display: flex;
   flex-flow: row wrap; 
   justify-content: space-between;
   font-family: var(--font-family);
   font-style: normal;
   font-weight: normal;
   font-size: 16px;
   line-height: 24px;
   padding-right: 1rem;

color: #000000;
}
.location li {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin-left: 1rem;
}
.location li img{
    margin-top: -.9rem;
}
.pro{
    margin-top: 1.25rem;
    /* margin-bottom: 1rem; */
}
.pro li p{
    margin-top: .5rem;
}
main .cont-wrapper .left-desc .description div p{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
}
.form-update {
    /* margin-left: 12%; */
    width: 80%;
    margin: auto;
}
.form-update button[type="submit"]{
    /* width: 85%; */
    width: 100%;
    margin: auto;
    margin-top: 2rem;
}
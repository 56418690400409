/* Import css variables */
@import "../../../variables.styles.css";
section .heading_title {
    width: 80%;
    height: auto;
    padding: 1rem;
    margin-top: 20%;
    margin-left: 12.1%;
}
section .heading-title .heading_title--review img{
    width: 20px;
    height: 20rem;
}
section .heading_title .heading_title--wrapper 
 .heading_title--review p {
    color: orange;
}
section .heading_title .describe{
    margin-top: 2rem;
}


.heading_title .review_added .image-copy p{
    margin-top: .5rem;
    margin-left: .8rem;
}
.heading_title .review_added .words,
.heading_title .review_added .year-pub {
    margin-left: 3.2rem;
} 
@media(max-width: 600px){
    section .heading_title {
        margin-left: 5%;
        width: 90%;
    }
}
/* Import css variables */
@import '../../../../variables.styles.css';

.tracker_talent h3 {
  color: var(--deepBlue-background);
  margin-left: 6%;
  margin-top: 0.87rem;
}

.tracker_talent .tracker_nav .date_style {
  margin-right: 3%;
}

.tracker_talent .tracker_nav .date_style h6 {
  padding: 1rem;
  font-size: 0.9rem;
}
.tracker_talent .tracker_nav .date_style h6 span {
  font-weight: 590;
}
.tracker_talent .tracker_nav .tracker_nav--button {
  width: 6rem;
  height: 40px;
  background: var(--lightBlue);
  margin-left: 1.9rem;
  color: #ffffff;
  border: none;
  font-size: 0.87rem;
  border-radius: 25px;
  margin-left: 6%;
}

/* BODY CARDS AND MAIN TRACKER SECTION */
.cards_wrapper_talent {
  width: 90%;
  margin-left: 6%;
  height: auto;
  padding: 1.8rem 0 1.8rem 0;
  background-color: #5755b4;
}

.cards_wrapper_talent .todo_wrapper {
  width: 30%;
  /* height: auto; */
  padding-bottom: 1rem;
  background-color: #ebecf0;
}

.cards_wrapper_talent .todo_wrapper .cards_wrapper_talent--main {
  width: 100%;
  border-radius: 0;
}

.cards_wrapper_talent--main h6 {
  padding: 0 0 0 1.5rem;
}

.cards_wrapper_talent .cards_wrapper_talent--main {
  width: 27%;
  height: 6vh;
  position: relative;
}

.cards_wrapper_talent .cards_wrapper_talent--main .add_iconTalent {
  position: absolute;
  color: var(--deepBlue-background);
  width: 1.5rem;
  height: 1.5rem;
  right: 10%;
  top: 0.5rem;
}

.cards_wrapper_talent .cards_wrapper_talent--main h6 {
  margin-top: 0.5rem;
  font-weight: 590;
}

/* Form style */
.cards_wrapper_talent .todo_wrapper .tracker_form {
  width: 90%;
  height: 100%;
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
  padding-top: 2rem;
  padding-bottom: 2rem;
  background: #ffffff;
  border: 1px solid rgba(119, 112, 112, 0.35);
  box-sizing: border-box;
  box-shadow: 0px 0px 2px rgba(143, 137, 137, 0.25);
}

.cards_wrapper_talent .todo_wrapper .tracker_form form {
  width: 90%;
  margin: auto;
}

.cards_wrapper_talent
  .todo_wrapper
  .tracker_form
  form
  .form_group_tracker
  label {
  font-size: 0.87rem;
}

.cards_wrapper_talent
  .todo_wrapper
  .tracker_form
  form
  .form_group_tracker
  input[type='text'],
.cards_wrapper_talent
  .todo_wrapper
  .tracker_form
  form
  .form_group_tracker
  select,
.cards_wrapper_talent
  .todo_wrapper
  .tracker_form
  form
  .form_group_tracker
  input[type='date'] {
  width: 100%;
  height: 35px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.47);
  box-sizing: border-box;
  border-radius: 25px;
  outline: none;
  padding-left: 1rem;
}

.cards_wrapper_talent
  .todo_wrapper
  .tracker_form
  form
  .form_group_tracker
  select,
.cards_wrapper_talent
  .todo_wrapper
  .tracker_form
  form
  .form_group_tracker
  label {
  margin-top: 0.37rem;
}

.cards_wrapper_talent .todo_wrapper .tracker_form form .tracker_form_button {
  width: 100%;
}

.cards_wrapper_talent
  .todo_wrapper
  .tracker_form
  form
  .tracker_form_button
  button[type='submit'] {
  width: 42%;
  height: 38px;
  font-size: 0.87rem;
  font-size: 0.8rem;
  margin-left: 0.2rem;
}

.cards_wrapper_talent
  .todo_wrapper
  .tracker_form
  form
  .tracker_form_button
  button[type='button'] {
  width: 42%;
  height: 38px;
  background: #ffffff;
  border: 1px solid #020079;
  box-sizing: border-box;
  border-radius: 25px;
  font-size: 0.8rem;
}

.cards_wrapper_talent .todo_wrapper .created_card {
  width: 80%;
  min-height: 70px;
  margin: 1rem auto 0 auto;
}

@media (max-width: 600px) {
  .cards_wrapper_talent .todo_wrapper {
    width: 100%;
  }
  .cards_wrapper_talent .cards_wrapper_talent--main {
    width: 100%;
  }
}

@media (max-width: 1000px) {
  .cards_wrapper_talent .todo_wrapper {
    width: 100%;
  }
  .cards_wrapper_talent .cards_wrapper_talent--main {
    width: 100%;
  }
}

.desc-content-edit {
    max-width: 395px;
    height: auto;
    margin-left: 4.15%;
    margin-top: 12%!important;
    background: #ADD8E6;
    border: 1px solid rgba(0, 0, 0, 0.18);
    box-sizing: border-box;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 1% 0% 6% 0%;
}
.desc-content-edit .form-group-des label{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
}
.desc-content-edit .form-group-des{
    margin: 5% 5% .5% 5%;
}
.desc-content-edit .form-group-des textarea{
    min-width: 351px;
    height: 125px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.37);
    box-sizing: border-box;
    border-radius: 5px;
    outline: none;
    padding: 1rem;
}
.desc-content-edit .but{
    margin-left: 5%;
}
.desc-content-edit .but button[type="submit"]{
    width: 146px;
    height: 47.5px;
    background: #020079;
    border-radius: 25px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;  
    color: #FFFFFF;
    margin-left: .4rem;
}
.desc-content-edit .but button[type="button"]{
    width: 146px;
    height: 47.5px; 
    background: #FFFFFF;
    /* Primary/Deep blue */    
    border: 1px solid #020079;
    box-sizing: border-box;
    /* Button/secondary */    
    border-radius: 25px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: #020079;
}

.lang-edu{
    display: flex;
    flex-flow: row wrap;  
    align-items: center; 
    margin-left: 4%; 
    margin-top: 2.5rem;
}

.lang-edu h5{
    font-family: var(--font-family);
    font-size: 14px;
    font-weight: 600;
    margin-top: .6rem;
    color: var(--light-color-text);
   }
   .lang-edu p {
    margin-top: 1.1rem;
    margin-left: 1rem;
    font-size: 14px;
    font-family: var(--font-family);
    font-weight: normal;
    color: var(--light-color-text);
   }

.lang-bottom{
    display: flex;
    flex-direction: row;
    margin-left: 4%;
    margin-bottom: -3rem;
}
.lang-bottom p, .lang-bottom {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.6);
}
.lang-bottom span {
    margin-left: .5rem;
}
.lang-edu .edit-images{
    margin-left: 1%;
   }
.lang-edu .edit-images img {
    cursor: pointer;
   }
.lang-edu .edit-images img:nth-child(2){
    margin-left: .5rem;
   }
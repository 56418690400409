.knowmore{
    margin-top: 50px;
}
.knowimage{
    padding-left: 5%; 
}
.know_words{
    margin-left: 100px;
}
.knowimg{
    width: 300px;
    height: 350px;
}
.know_desc{
    font-size: 18px;
}
.know_h2{
    margin-top: 10px;
    color: #020079;
}
.know_star{
    background-color: gold;
}
.know_all{
    display: flex;
    flex-direction: row;
    width: 90%;
    justify-content: space-between;
    margin-left: 5%;
}
@media(max-width: 950px){
    .knowimage{
        margin-left: 15%;
        padding-left: 10px;
        width: 400px;
    }
    .know_words{
        margin-left: 0px;
    }
    .know_all{
        margin-left: 10%;
        margin-top: 20px;
        flex-direction: column;
    }
}
@media(max-width: 500px){
    .knowimage{
        margin-left: 0;
        padding-left: 10px;
    }
    .know_words{
        margin-left: 0px;
    }
}
@media(max-width: 500px){
    .knowimage{
        margin-left: 0;
        padding-left: 10px;
        width: 350px;
    }
    .know_words{
        margin-left: 0px;
    }
}
/* Import css variables */
@import '../../../../variables.styles.css';

.created_card {
  width: 90% !important;
  position: relative;
  display: block;
  /* flex-direction: column; */
  padding: 1rem 1rem 2rem 1rem;
}

.created_card p {
  font-size: 0.83rem;
  font-weight: 590;
  /* text-align: center; */
  padding: 0rem 0rem 1rem 0rem;
}

.created_card .doing {
  background-color: #ec9488;
  font-size: 0.83rem;
  padding: 0.2rem 0.5rem;
  border-radius: 5px;
  color: #ffff;
}
.created_card .done {
  background-color: #61bd4f;
  font-size: 0.83rem;
  padding: 0.2rem 0.5rem;
  color: #ffff;
  border-radius: 5px;
}

.created_card .action_tracker_icon {
  position: absolute;
  right: 5%;
  bottom: 0rem;
}

.created_card .action_tracker_icon img {
  cursor: pointer;
  margin-left: 0.9rem;
}

/* .created_card .action_tracker_icon img:nth-child(2){
    width: 22px;
} */

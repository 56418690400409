/* Import css variables */
@import "../../../variables.styles.css";

/* THE LEFT LAYOUT STYLE */
.signup-wrapper .signup-wrapper-child-1 .left-forgot{
    /* height: 100vh; */
    background: var(--deepBlue-background);
}

.signup-wrapper .signup-wrapper-child-1 .left-forgot h1{
    /* width: 365px;
    height: 118px; */
    font-family: var(--font-family);
    font-style: normal;
    font-weight: bold;
    font-size: 1.8rem;
    line-height: 60px;
    font-feature-settings: 'liga' off;
    color: #FFFFFF;
}

.signup-wrapper .signup-wrapper-child-1 .left-forgot div{
   margin-left: 10%;
   margin-top: 16%;
}

.signup-wrapper .signup-wrapper-child-1 .left-forgot div img{
    /* width: 447px;
    height: 223.29px; */
    margin-top: 8%;
    margin-right: 2%;
}

/* THE RIGHT LAYOUT STYLE */
.signup-wrapper .signup-wrapper-child-1 .right-forgot{
    padding-top: 6%;
}
.signup-wrapper .signup-wrapper-child-1 .right-forgot h1{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 60px;
    font-feature-settings: 'liga' off;
    color: #020079;
    margin-left: 8%;
}

.signup-wrapper .signup-wrapper-child-1 .right-forgot h6{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    font-feature-settings: 'liga' off, 'calt' off;
    color: rgba(2, 0, 121, 0.65);
    margin-left: 8%;
    max-width: 426px;
}

/* THE FORM */
.signup-wrapper .signup-wrapper-child-1 .right-forgot form{
    margin-top: 40px;
    margin-left: 8%;
}
.signup-wrapper .signup-wrapper-child-1 .right-forgot form .form-input{
    margin-bottom: 1rem;   
}
.signup-wrapper .signup-wrapper-child-1 .right-forgot p{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.8);
}
.signup-wrapper .signup-wrapper-child-1 .right-forgot p{
    margin-left: 8%;
    margin-top: 1rem;
}
.signup-wrapper .signup-wrapper-child-1 .right-forgot p span{
    color: #020079!important;
    text-decoration: underline;
}

/* MOBILE RESPONSIVENESS */
@media(max-width: 500px){
    .signup-wrapper .signup-wrapper-child-1 .left-forgot {
        display: none;
    }
    .signup-wrapper .signup-wrapper-child-1 .right-forgot
    .mobile-center,
    .signup-wrapper .signup-wrapper-child-1 .right-forgot
    h1,
    .signup-wrapper .signup-wrapper-child-1 .right-forgot
     h6{
        text-align: center;
        margin-left: 0;
    }
    .signup-wrapper .signup-wrapper-child-1 .right-forgot
    .social-buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .signup-wrapper .signup-wrapper-child-1 .right-forgot
    .social-buttons button[type="button"]:nth-child(2){
        margin-left: 0;
    }
    .signup-wrapper .signup-wrapper-child-1 .right-forgot
    .line {
        flex-direction: column;
        margin-left: 0;
    }
    .signup-wrapper .signup-wrapper-child-1 .right-forgot
     form {
         margin-left: 0;
     }
 }

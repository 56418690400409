/* Import css variables */
@import '../../../../variables.styles.css';

.tracker_settings h3 {
  color: var(--deepBlue-background);
  margin-left: 2rem;
  margin-top: 1rem;
}

.tracker_settings .tracker_settings_button button[type='button'] {
  color: #ffffff;
  border-radius: 25px;
  border: none;
  font-size: 0.87rem;
  margin-top: 2.5%;
}

.tracker_settings .tracker_settings_button .tracker_settings_button--back {
  width: 6rem;
  height: 40px;
  background: var(--lightBlue);
  margin-left: 1.9rem;
}

.tracker_settings .tracker_settings_button .tracker_settings_button--add {
  width: 7.5rem;
  height: 42px;
  background: var(--deepBlue-background);
  margin-right: 1.9rem;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.tracker_add {
  width: 30px;
  height: 30px;
  color: var(--deepBlue-background);
  cursor: pointer;
}

.invite {
  margin-left: 0.5rem;
}

.tracker_settings .tracker_settings_button .action_icons {
  margin-right: 5%;
}

/* BODY CARDS AND MAIN TRACKER SECTION */
.cards_wrapper-hunt {
  /* width: 95%;
  margin: auto;
  height: auto;
  padding: 1rem 0 1rem 0; */
  position: relative;
  /* background-color: #5755b4; */
}

.cards_wrapper-hunt .delete_icon_hunter {
  width: 1.3rem;
  height: 1.3rem;
  position: absolute;
  right: 4%;
  top: 10%;
  cursor: pointer;
}

.cards_wrapper .cards_wrapper--main {
  width: 30%;
  height: 6vh;
  margin-top: 3rem;
  padding: 0.5rem 0 0rem 0.5rem;
}

.cards_wrapper .cards_wrapper--main h6 {
  /* margin-top: 0.5rem; */
  font-weight: 590;
}

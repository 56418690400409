/* Import css variables */
@import "../../../variables.styles.css";


.job_request h3{
    margin-left: 5.5%;
    color: var(--deepBlue-background);
    font-weight: 580;
}

.job_request .tab_job_wrapper {
    width: 90%;
    margin-left: 6%;
    margin-top: 1.3rem;
    box-shadow: 0 3px 10px rgb(0, 0, 0,  0.267);
}
/* Import css variables */
@import "../../../variables.styles.css";

.dashboard_home h3 {
    margin-left: 3%;
    color: var(--deepBlue-background);
}

.dashboard_home h4 {
    margin-left: 3%;
    color: var(--deepBlue-background);
    font-size: 1.2rem;
    font-weight: 600;
}
.dashboard_home .tab_wrapper {
    width: 85%;
    margin-left: 3%;
    margin-top: 2rem;
}
.dashboard_home .dash_heading a button[type="button"]{
    margin-right: 4.5rem!important;
    margin-top: 4%;
    min-width: 9rem;
    height: 2.8rem;
    border: none;
    outline: none;
    border-radius: 25px;
    background: var(--deepBlue-background);
    color: white;
    font-size: .89rem;
}
.triangle-down {
	width: 0;
	height: 0;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-bottom: 10px solid #555;
}
.overtooltip{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 150px;
}
.tooltipopen{
    background-color: #555;
    color: white;
    padding: 3px;
    border-radius: 3px;
}
.openedjobs{
    width: 130px;
}
.closedjobs{
    width: 100px;
}
.openedjobs .toolholder{
    /* visibility: hidden; */
    display: none;
}
.closedjobs .toolholder{
    /* visibility: hidden; */
    display: none;
}
.openedjobs:hover .toolholder{
    /* visibility: visible; */
    display: block;
    position: absolute;
}
.closedjobs:hover .toolholder{
    /* visibility: visible; */
    display: block;
    position: absolute;
}
.openandclosedjobs{
    margin-top: 15px;
}
.edit__job{
    height: 48px;
    left: 727px;
    top: 714px;
    background: #020079;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    border-radius: 25px;
    font-family: VAR(--font-family);
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    border: none;
    width: 85%;
    margin-top: 2rem
}


header {
    width: 100%;
    /* height: auto; */
    /* padding-top: 1rem;
    padding-bottom: 1rem; */
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

header .nav_wrapper {
    margin-top: -3.7rem;
}

header nav .links_wrapper {
    display: flex;
    align-items: center;
    /* margin-top: 2.6rem!important; */
}

/* registration options */
.registration__options{
    padding: 1.3rem 1rem;
    position: relative;
}
 .registration__options a{
    
        text-decoration: none;
        font-weight:700;
       line-height: 1.35;
        font-size:1rem;
 }
.list-showup{
    background-color:#fff;
    list-style: none;
    border: 2px solid rgb(240, 237, 237);
    padding: 0.5rem 0;
    /* padding: 0.5rem 0 0.5rem 1rem; */
     top: 3rem;
    z-index: 999;
    width: 10rem;
    right: 4rem;
    max-height: 10rem;
    border-radius: 5px;
    overflow-y: auto;
     position: fixed;
    box-shadow: 0px 4px 4px 0px #00000040;
}

.list-showup-item{
    display: inline-block;
    width: 100%;
    padding: 0.5rem;
    text-align: center;
    margin-left: 0
}
.list-showup .list-showup-item a{
    color: rgba(0,0,0,.55);
}
.list-showup .list-showup-item:hover a {
    
    color: #fff;
}
.list-showup .list-showup-item:hover {
    background-color: #020079;
    
}
.nav_input{
    width: 24%;
    height: 6.7vh;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.16);
    box-sizing: border-box;
    border-radius: 50px;
    outline: none;
    margin-top: 2%;
    margin-left: 3%;
    background: url(../../../assets/image/search-icon.svg) no-repeat scroll 13px 9px;
    padding-left: 2rem;
}
input[type="search"]::placeholder {
    padding-left: 1rem;
}
header nav .links_wrapper .profile_drop {
    margin-top: -.6rem;
}
header .nav_wrapper li a .nav_button{
    width: 120px;
    height: 44px;
    background: #020079;
    border: 2px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 25px;
    font-style: normal;
    font-weight: 500;
    font-size: .875rem;
    line-height: 24px;
    color: #FFFFFF;
    margin-top: .5rem;
} 

header .nav_wrapper li .nav_button{
    width: 120px;
    height: 44px;
    background: #020079;
    border: 2px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 25px;
    font-style: normal;
    font-weight: 500;
    font-size: .875rem;
    line-height: 24px;
    color: #FFFFFF;
    margin-top: .5rem;
} 
.sublanding_list{
    list-style-type: none !important;
    display: flex;
    justify-content: space-around;
    color: rgba(0, 0, 0, 0.7);
    margin-top: 15px;
}
.homenav_landingheader{
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
}
@media (max-width: 1000px){
    .sublanding_list{
        display: none;
    }
    .homenav_landingheader{
        border-bottom: none;
    }
}
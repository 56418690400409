/* Import css variables */
@import "../../../../variables.styles.css";

.total-lang{
    margin-bottom: -4rem;
    margin-top: -3rem;
}
.lang {
 width: 100%;
 display: flex;
 flex-flow: row wrap;  
 align-items: center; 
 margin-left: 4%; 
 margin-top: 2.5rem;
 margin-bottom: -3rem;
 /* position: relative; */
}
.lang h5{
 font-family: var(--font-family);
 font-size: 14px;
 font-weight: 600;
 margin-top: .6rem;
 color: var(--light-color-text);
}
.lang p {
 margin-top: 1.1rem;
 margin-left: 1rem;
 font-size: 14px;
 font-family: var(--font-family);
 font-weight: normal;
 color: var(--light-color-text);
}
.total-lang .lang .edit-images{
 margin-left: 1%;
 display: hidden;
}
.total-lang .lang .edit-images:hover{
 display: block;   
}
.total-lang .lang .edit-images img {
 cursor: pointer;
}
.total-lang .lang .edit-images img:nth-child(2){
 margin-left: .5rem;
}

/* The form  */
.desc-content{
    margin-top: 3rem;
}

header nav {
 margin-left: 5.5%;
 margin-right: 5.5%;
}

header hr {
    margin-top: .5rem;
}
header nav ul {
    margin-top: 2.4%;
}
header nav ul li{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin-left: .8rem;
}
header nav ul .btn-group .dropdown-menu {
    left: -350%!important;
}
header nav ul .img-profile img {
    width: 48px;
    height: 48px;
    cursor: pointer;
}

/* Bottom nav style */
header nav .bottom-nav {
margin-top: -1%;
}

header nav .bottom-nav li {
    margin-left: 1rem;
}
header nav .bottom-nav li {
    font-family: var(--font-family)!important;
    font-style: normal!important;
    font-weight: 500!important;
    font-size: 14px;
    line-height: 24px;
    color: #000000;
}
/* Import css variables */
@import "../../../variables.styles.css";

.flunterwave{
    width: 60% ;
    border: 1px solid gray;
    border-radius: 10px;
}


.job_wrapper h4 {
    font-weight: 590;
    margin-left: 9%;
}
.job_wrapper form {
    margin-top: 2rem;
}
.job_wrapper form .job_field {
    margin-top: .7rem;
}
.job_wrapper form .job_field label {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 600;
    font-size: .87rem;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.8);
} 
.job_wrapper form .job_field select,
.job_wrapper form .job_field input {
    width: 52%;
    height: 48px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    border-radius: 25px; 
    padding-left: 1rem;
    padding-right: 1rem;
    outline: none;
}
.job_wrapper form .job_field select option {
    font-size: .84rem;
}
.job_wrapper form .job_field textarea {
    width: 52%;
    height: 18vh;
    padding: .9rem;
    outline: none;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.2);
}
@media (max-width: 600px){
    .job_wrapper form .job_field select,
.job_wrapper form .job_field input {
    width: 85%;
}
.job_wrapper form .job_field textarea {
    width: 85%;
}
}
@media (max-width: 1000px){
    .job_wrapper form .job_field select,
.job_wrapper form .job_field input {
    width: 90%;
}
.job_wrapper form .job_field textarea {
    width: 90%;
}
.job_milestone{
    width: 100% !important;
}
}
.editjob_milestone{
    width: 100% !important;
    background-color: rgba(0, 0, 0, 0.05);
    padding: 10px ;
    border-radius: 10px;
    margin-bottom: 10px;
}
@media (max-height: 500px){
    .editjob_milestone{
        overflow-y: scroll;
    }
}

/* job milestone */
.job_milestone{
    display: flex;
    width: 60%;
    padding: 0.5rem;
    box-shadow: 1px 2px 3px 4px rgba(20, 20, 20, 0.137);
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0.5rem 0;
    align-items: flex-start;
}
.milestone_add{
    border: none;
    padding: 5px 30px;
    background-color: #020079;
    color: white;
    border-radius: 20px;
    margin: 10px 0;
}
.milestone_add:hover{
    color: white;
}
.milestone_label{
    color: rgba(0, 0, 0, 0.8);
    font-style: normal;
    font-weight: 500;
    font-size: .87rem;
    margin-top: 10px;
    min-height: 7rem;
}

.milestone_label2{
    color: rgba(0, 0, 0, 0.8);
    font-style: normal;
    font-weight: 500;
    font-size: .87rem;
    margin-top: 10px;
    min-height: 9rem;
}
.milestone_created{
    font-weight: 600;
}
.milestone_input{
    border-radius: 10px;
}
.job_field_card{
    flex: 0 0 47%;
    min-height: 13rem;
    /* background-color: #020079; */
    margin-bottom: 0.5rem;
}
.job_field_card input{
    width: 100%;
    padding: 1.2rem 0.5rem;
    outline: none;
    border: 1px solid grey;
}

.job_field_card textarea{
    width: 100%;
    padding: 0.5rem 0.5rem;
    }
    .milestone_table{
        width: 100%;
    }
.milestones_modaltitle{
    color: white;
}

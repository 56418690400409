.howitworks{
    text-align: center;
    color: white;
}
.works_paragraph{
    font-size: 25px;
    width: 80%;
    margin-left: 10%;
    color: white;

}
.empty{
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: white;
}
.coloredback{
    padding-left: 10%;
}
.coloredback2{
    margin-bottom: 30px;
}
.emptydiv{
    display: flex;
    background-color: #02007966;
    padding: 10px;
    border-radius: 20px;
    width: 400px;
    margin-top: 40px;
}
.generalhow{
    padding-top: 50px;
    background-color: #020079 ;
}
.howpacked{
    display: flex;
    width: 80%;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 10%;
    margin-top: 30px;
}
@media(max-width: 900px){
    .how_img{
        margin-left: 15%;
        margin-top: 20px;
    }
    .howpacked{
        flex-direction: column;
    }
}
@media(max-width: 500px){
    .how_img{
    margin-left: 2%;
    width: 350px;
    }
    .emptydiv{
        max-width: 350px;
    }
    .empty{
        width: 15px;
        height: 15px;
        margin-top: 10px;
    }
    
}
@media(max-width: 400px){
    .how_img{
    margin-left: 2%;
    width: 100%;
    height: 300px;
    }
    .emptydiv{
        max-width: 250px;
    }
}
@media(min-width: 501px) and (max-width: 700px){
    .how_img{
    margin-left: 10%;
    width: 80%;
    }
}

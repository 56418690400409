/* Import css variables */
@import "../../../../variables.styles.css";

.edit-desc{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin-top: 24px;
}
.edit-desc p{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin-left: 1rem;
}
.edit-desc button[type="button"]{
    width: 86px;
    height: 30px;
    background: #020079;
    border-radius: 25px;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
    border: none;
    margin-right: 1rem;
}
.desc-content {
    max-width: 395px;
    height: auto;
    margin-left: 4.15%;
    margin-top: 1%!important;
    background: #ADD8E6;
    border: 1px solid rgba(0, 0, 0, 0.18);
    box-sizing: border-box;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 1% 0% 6% 0%;
}
.desc-content .form-group-des label{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
}
.desc-content .form-group-des{
    margin: 5% 5% .5% 5%;
}
.desc-content .form-group-des textarea{
    min-width: 351px;
    height: 125px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.37);
    box-sizing: border-box;
    border-radius: 5px;
    outline: none;
    padding: 1rem;
}
.desc-content .but{
    margin-left: 5%;
}
.desc-content .but button[type="submit"]{
    width: 146px;
    height: 47.5px;
    background: #020079;
    border-radius: 25px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;  
    color: #FFFFFF;
    margin-left: .4rem;
}
.desc-content .but button[type="button"]{
    width: 146px;
    height: 47.5px; 
    background: #FFFFFF;
    /* Primary/Deep blue */    
    border: 1px solid #020079;
    box-sizing: border-box;
    /* Button/secondary */    
    border-radius: 25px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: #020079;
}
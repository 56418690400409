.try_h2{
    /* color: #020079; */
    color: white;
    width: 45%;
}
.trygroup{
    padding-top: 80px;
    margin-left: 10%;
    padding-bottom: 80px;
}
.try{
    background-image: url('./unsplash_koo_vYrlU_U.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.trygroup button{
    border: none;
    background-color: #020079;
    color: white;
    padding: 10px 40px;
    border-radius: 25px;
}
@media(max-width: 500px){
    .try_h2{
        width: 80%;
    }
}
/* Import css variables */
@import "./variables.styles.css";
@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none !important;
  font-family: var(--font-family);
}

.modal-backdrop {
  background-color: rgba(0,0,0,0.2);
}
.Toastify__toast--error {
  border: 1px solid #eb5757;
  border-radius: 50px !important;
  background: #fae1e2 !important;
}
.Toastify__toast--error::after {
  /* content : url('../assets/images/svg/closeToast.svg'); // Your svg Path */
  position: absolute;
  color: #333333;
  font-size: 15px;
  font-weight: 700;
  left: 265px;
  padding-top: 14px !important;
}
.Toastify__toast--error::before {
  /* content: url("../assets/images/svg/errorToast.svg");// Your svg Path */
  position: relative;
  z-index: 100000;
  left: 12px;
  top: 6px;
}
.Toastify__toast--success {
  border: 1px solid #3a9ea5 !important;
  border-radius: 10px !important;
  background: #f0f9fa !important;
  /* width: 250px;
height: 10px !important; */
}
.Toastify__toast--success::before {
  /* content: url("../assets/images/svg/successToast.svg");// Your svg Path */
  position: relative;
  z-index: 100000;
  left: 12px;
  top: 6px;
}
.Toastify__toast--success::after {
  /* content : url('../assets/images/svg/closeToast.svg');// Your svg Path */
  position: absolute;
  color: #333333;
  font-size: 15px;
  font-weight: 700;
  left: 265px;
  padding-top: 14px !important;
}
.Toastify__toast--warning {
  border: 1px solid #e78326 !important;
  border-radius: 50px !important;
  background: #fadfc5 !important;
}
.Toastify__toast--warning::before {
  /* content: url("../assets/images/svg/warnToast.svg");// Your svg Path */
  position: relative;
  z-index: 100000;
  left: 12px;
  top: 6px;
}
.Toastify__toast--warning::after {
  /* content : url('../assets/images/svg/closeToast.svg'); // Your svg Path */
  position: absolute;
  color: #e78326;
  font-size: 15px;
  font-weight: 700;
  left: 265px;
  padding-top: 14px !important;
}
.Toastify__toast-body {
  color: #444c63;
  font-size: 16px;
  padding-left: 20px;
  line-height: 20px;
  padding: 0px;
  padding-top: 25px;
  width: 100%;
  font-weight: 400;
  margin-left: 25px !important;
}
.Toastify__toast > button > svg {
  display: none;
}
.nav-tabs .nav-link {
  border-radius: 1px solid black;
  background-color: rgba(2, 0, 121, 0.65);
  color: #f0f9fa;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #495057;
  background-color: rgba(140, 139, 190, 0.65);
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs {
  border-bottom: 1px solid rgba(2, 0, 121, 0.65);
}

/* Import css variables */
@import "../../../../variables.styles.css";


.categories-submenu {
    width: 20%;
    height: auto;
    padding-top: 1rem;
    padding-bottom: 1rem;
    position: absolute;
    list-style: none;
    text-align: start;
    top: -1rem;
    margin-left: -2.5rem;
    transition: 1s all ease-in-out;
}

.categories-submenu li {
   background: var(--lightBlue);
   cursor: pointer;
   transition: 1s all ease-in-out;

}

.categories-submenu li a:hover{
  background: var(--deepBlue-background);
  color: #FFFFFF;
}


/* When submenu is clicked */

.categories-submenu.clicked {
   display: none;
 }
 

.submenu-item {
    display: block;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: white;
    padding: 16px;
}

/* Import css variables */
@import "../../../variables.styles.css";

main .cont-wrapper {
    margin-top: 5%;
}
/* Left */
main .cont-wrapper .left-desc .description {

    margin-left: 14%!important;
    max-width: 438px;
    min-height:548px;
    max-height: 648px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.35);
    box-sizing: border-box;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
    
}
main .cont-wrapper .left-desc .description .elipse-img {
    margin-top: 4rem;
    cursor: pointer;
    }
main .cont-wrapper .left-desc .description .status-online {
    width: 91px;
    height: 22px;
    background: #ADD8E6;
    background-size: cover;
    border-radius: 10px;
    margin-right: 2rem;
    margin-top: 1.2rem;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.8);
    text-align: center;
    padding-top: .1rem;

}
main .cont-wrapper .left-desc .description div p{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.8);
    text-align: center;
    
    /* padding-top: 1rem; */
}
main .cont-wrapper .left-desc .description h6 {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    text-align: center;
    margin-top: 2rem;
}
main .cont-wrapper .left-desc .description .edit {
    margin-top: 1.5rem;
    cursor: pointer;
}
main .cont-wrapper .left-desc .description .preview 
button[type="button"]{
    width: 389px;
    height: 48px;
    background: #020079;
    border-radius: 25px;
    border: none;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    margin-top: 1.8rem;
}
main .cont-wrapper .left-desc .description hr {
    margin-top: 3rem;
}
/* Right */
main .cont-wrapper .right-desc h4 {
    text-align: center;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px; 
    color: rgba(0, 0, 0, 0.8);
    margin-top: 10%;

}
main .cont-wrapper .right-desc .create-gig{
    padding: 2rem 1rem 0 1rem;
}
main .cont-wrapper .right-desc .create-gig .gig-content{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
}
main .cont-wrapper .right-desc .create-gig .gig-content p {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.8);
}
main .cont-wrapper .right-desc .gig-content button[type="button"]{
    width: 166px;
    height: 40px;
    background: #020079;
    border-radius: 25px;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
    border: none;
}
main .cont-wrapper .right-desc .create-gig{
    max-width: 600px;
    height: 109px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.35);
    box-sizing: border-box;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
    margin-left: 11%;
}
.other-feat-cards{
    max-width: 438px;
    height: auto;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.35);
    box-sizing: border-box;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
    margin-left: 14%!important;
    margin-top: 48px;
    padding-top: 2rem;
    padding-bottom: 2rem;
}
.other-feat-cards hr{
    margin-top: 5rem;
}
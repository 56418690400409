.policy{
    width: 90%;
    margin-left: 5%;
    margin-top: 30px;
}
.policy_header{
    text-decoration: underline !important;
    color: #020079;
}
.policy__paragraph{
    margin-top: 20px;
}
.policy__paragraph1{
    margin-top: 20px;
    padding-bottom: 10px;
}
.policy__bold{
    font-weight: 600;
}
.policy_subhead{
    text-decoration: underline !important;
    color: #020079;
    margin-top: 40px;
    font-weight: 600;
}
.policy_info{
    color: #020079;
}
.policy_desc{
    margin-top: 20px;
}
.agreement_explanation{
    color: #020079;
    margin-top: 10px;
}
.agreement_subhead{
    margin-top: 30px;
    font-weight: 600;
    color: #020079;
}
.agreement_words{
    width: 98%;
    margin-left: 2%;
}
.agreement_words1{
    width: 98%;
    margin-left: 2%;
    margin-top: 20px;
}
/* Import css variables */
@import "../../../../variables.styles.css";

section .gig-title h1{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 60px;    
    font-feature-settings: 'liga' off;    
    color: #020079;
    margin-left: 6%;
}
section .gig-title h6 {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    font-feature-settings: 'liga' off, 'calt' off;
    color: rgba(2, 0, 121, 0.65);
    margin-left: 6%;
}

/* Indicator styles */
.indicator-container {
    margin: 2% 0 0 6%;
}
.indicator-container .overview p{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: rgba(14, 14, 14, 0.5);
}
.indicator-container .overview img {
    margin-top: -2.5rem;
}
.indicator-container .add-space {
    margin-left: 1.2rem;
}

/* Form area */
section .overview-heading h3{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: bold;
    font-size: 28x;
    line-height: 32px;
    color: #020079;
    margin-left: 6%;
}
section .overview-heading h6{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    color: rgba(2, 0, 121, 0.65);
    margin-left: 6%;
}
section hr {
    max-width: 1219px;
    height: 0px;
    border: 1px solid rgba(0, 0, 0, 0.37);
    margin-left: 6%;
}
section form {
    margin-left: 9%;
    margin-top: -2%;
}
section form .form-group-gigs img{
    margin-bottom: -2.5rem;
    margin-left: 5rem!important;
    margin-top: .5rem;

}
section form .form-group-gigs label{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #020079;
}
section form .form-group-gigs select{
    min-width: 521px!important;
    height: 48px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    border-radius: 25px; 
    padding-left: 1rem;
    outline: none;
}
section form .top-space {
    margin-top: 1rem;
}
section form .gigs-button button[type="button"]{
    width: 151px;
    height: 48px;
    background: #FFFFFF!important;    
    border: 1px solid #020079;
    box-sizing: border-box;
    border-radius: 25px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #020079;
}
section form .gigs-button button[type="submit"]{
    width: 151px;
    height: 48px;
    background: #020079;
    border-radius: 25px;
    margin-left: .5rem;
}
section .overview-cont .overview-wrapper .right-overview{
    display: flex;
    margin-top: 7%;
}
section .overview-cont .overview-wrapper .right-overview img{
    width: 29px;
    height: 29px;
}
section .overview-cont .overview-wrapper .right-overview p {
    width: 422px;
    height: 143px;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 29px;
    color: rgba(0, 0, 0, 0.8);
    margin-left: 1.5%;
}
/* DESCRIPTION */
section form .text label{
    margin-left: -14%;
    margin-top: 1.8rem!important;
}
section form .text textarea {
    margin-top: .3rem;
    min-width: 521px!important;
    height: 125px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.37);
    box-sizing: border-box;
    border-radius: 5px;
    outline: none;
    padding: 1rem;
}

/* FAQ */
section .add-form{
    margin-left: 6%;
}
section .add-form button[type="button"]{
    width: 86px;
    height: 30px;
    background: #020079;
    border-radius: 25px;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
    border: none;
}
@media (max-width: 600px) {
    section form .text textarea {
        min-width: 415px!important;
    }
}
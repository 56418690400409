/* Import css variables */
@import "../../variables.styles.css";

.form-group {
    margin-top: 1rem;
}

.form-group label{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.8);
}

.form-group input{
    /* min-width: 458px; */
    width: 55%;
    height: 48px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    border-radius: 25px; 
    padding-left: 1rem;
    outline: none;
}

/* MOBILE RESPONSIVENESS */
@media(max-width: 500px){
    .form-group input{
        width: 100%;
    }
}
@media(max-width: 900px){
    .form-group input{
        width: 90%;
    }
}

.paymentbid{
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 1rem;
    
}

.paymentbid__info{
    width: 60%;
    display: flex;
    justify-content: space-between;
}
.paymentbid__image{
    width: 15%;
    
    text-align: center;
    height: 15vh;
    /* height: 10vh; */
    
}

.paymentbid__image svg{
    font-size: 5rem;
    
}
.paymentbid__table{
width:100%
}

/* @import "../../../../variables.styles.css" */
@import '../../../variables.styles.css';

.paymentbid__table table{
    border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #ddd;
}

.paymentbid__button{
    padding: 0.5rem 2rem;
    border-radius: 2rem;
    outline: none;
    border: none;
    color: #fff;
    background-color: var(--deepBlue-background);
}

.paymentbid__table thead {
    background-color: rgb(238, 237, 237);
  }
  
  .paymentbid__table th {
    padding: 0.8rem 0.3rem;
  }

  .paymentbid__table tbody td{
    padding: 0.5rem;
  }
  .paymentbid__table .tr-foot {
    background-color: rgb(238, 237, 237);
    padding: 0.5rem 0.3rem;
  }
  
.paymentbid__summary{
    width: 35%;
    border: 1px solid #ccc;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    background-color: #fff;
    padding: 1rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.paymentbid__title{
    width: 100%;
    border-bottom: 1px solid #ccc;
    text-align: center;
}
.payment__title h5{
    padding: 1rem 0;
    margin: 0;
    color: rgb(66, 65, 65);
}
.paymentbid__summary-detail{
    width: 100%;
    display: flex;
   justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid #ccc;
    margin-bottom: 1rem;

}
.paymentbid__summary-text{
    width: 50%;
    
}
.paymentbid__summary-price{
    
    text-align: end;
    width: 30%;
   
}
@media (max-width: 600px){
    .paymentbid{
        display: flex;
        flex-direction: column;
    }
    .paymentbid__summary{
        width: 100%;
    }
}
@media (max-width: 1100px){
    .paymentbid{
        display: flex;
        flex-direction: column;
    }
    .paymentbid__summary{
        width: 80%;
    }
}
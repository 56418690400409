/* Import css variables */
@import "../../../../../variables.styles.css";

.overview-cont .overview-wrapper .left-side
.profile-content-wrapper{
    margin-top: 2rem;
}

.overview-cont .overview-wrapper .left-side
.profile-content-wrapper img {
    width: 6rem;
    height: 6rem;
}

.overview-cont .overview-wrapper .left-side
.profile-content-wrapper .profile-content {
    margin-left: 1rem;
}


.overview-cont .overview-wrapper .left-side
.profile-content-wrapper .profile-content .five_star
img{
    width: 1.1rem;
    height: 1.1rem;
} 
.overview-cont .overview-wrapper .left-side
.profile-content-wrapper .profile-content h6 {
    font-weight: 590;
}

.overview-cont .overview-wrapper .left-side
.profile-content-wrapper .profile-content .five_star{
    margin-top: -.8rem;
}

.overview-cont .overview-wrapper .left-side
.card-copy {
    width: 85%;
    height: auto;
    padding: 1rem;
    margin-top: 2rem;
}

.overview-cont .overview-wrapper .left-side
.profile-content-wrapper button[type="button"]{
    width: 8rem;
    height: 2.3rem;
    border: none;
    outline: none;
    background: var(--deepBlue-background);
    color: #fff;
    border-radius: 25px;
    font-size: .87rem;
}

@media (max-width: 600px){
    .overview-cont .overview-wrapper .left-side
.card-copy {
    margin-bottom: 2rem;
}
}
/* Import css variables */
@import "../../../variables.styles.css";

.bid_table thead tr th{
    font-size: 1rem;
}

/* .bid_table tbody tr {
    margin-top: -1rem!important;
} */
.bid_table tbody tr td {
    font-size: .875rem!important;
}

.bid_table tbody tr .action_icons .action_icons--bidbutton {
    width: 6rem;
    height: 39px;
    background: var(--deepBlue-background);
    border: none;
    outline: none;
    border-radius: 25px;
    /* Text style */
    color: #fff;
    font-size: .79rem;
}
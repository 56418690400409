.image-box{
    display: flex;
    margin-top: 1rem;
}

.image-box .box-1,
.image-box .box-2,
.image-box .box-3 {
    width: 262px;
    min-height: 191px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.31);
    box-sizing: border-box;
    border-radius: 5px;
}
/* Import css variables */
@import "../../variables.styles.css";

.form-group-modal {
    margin-top: 1rem;
}

.form-group-modal label{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.8);
}

.form-group-modal input{
    /* min-width: 458px; */
    width: 98%;
    height: 48px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    border-radius: 25px; 
    padding-left: 1rem;
    outline: none;
}

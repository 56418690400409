/* Import css variables */
@import "../../../variables.styles.css";

.nav_header nav {
    width: 99.89%;
    height: auto;
    padding-top: 1rem;
    padding-bottom: 1rem;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.22);
    box-sizing: border-box;
    margin-left: 1px;
    margin-top: -2.8rem;
}

.nav_header nav img {
    width: 24px;
    height: 24px;
    cursor: pointer;
    margin-right: 3%;
}
@media (max-width: 600px) {
    .nav_header nav {
        width: 90%;
    }
}
.verify__page{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.verify__image{
    width:30rem;
    height: 30rem;
    margin-left: -10rem;
}
.overview-wrapper{
    display: flex;
    flex-direction: row;
}
.overview_part2{
    margin-left: 80px;
}
@media (max-width: 600px){
    .overview-wrapper{
        flex-direction: column;
    }
    .overview_part2{
        margin-left: 30px;
    }
}
@media (max-width: 900px){
    .overview-wrapper{
        flex-direction: column;
    }
    .overview_part2{
        margin-left: 30px;
    }
}
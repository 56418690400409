/* Import css variables */
@import "../../variables.styles.css";

.select-input {
    margin-top: 1rem;
}

.select-input input{
    /* min-width: 458px; */
    /* width: 98%!important; */
    min-width: 521px!important;

    height: 48px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    border-radius: 25px; 
    padding-left: 1rem;
    outline: none;
}
.select-input label{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */
    color: #020079;
}

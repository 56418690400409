/* Import css variables */
@import "../../../variables.styles.css";

.talents_gig h1 {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: bold;
    font-size: 2rem;
    line-height: 60px;
    font-feature-settings: 'liga' off;
    color: #020079;
    margin-top: 8rem;
    margin-left: 7%;
    text-transform: capitalize;
}
.gig_imgcard{
    width: 100%;
}
.talents_gig .gigs_card_wrapper {
    width: 100%;
    display: grid;
    /* grid-template-columns: 22% 22% 22% 22%; */
    grid-template-columns: repeat(auto-fit, 18rem);
    justify-content: center;
    /* margin-left: 6.5%; */
    margin-top: 1.5rem;
}

.talents_gig .gigs_card_wrapper .gigs_card {
    width: 90%;
    height: 359px;
    display: flex;
    justify-content: space-between;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
}

.talents_gig .gigs_card_wrapper .gigs_card h6 {
    font-size: .87rem;
    font-weight: 580;
    margin-left: .8rem;
    margin-top: .9rem;
    color: var(--deepBlue-background);
}

.talents_gig .gigs_card_wrapper .gigs_card p {
    font-style: normal;
    font-weight: 620;
    font-size: 1rem;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.8);
    margin-left: .8rem;
}

.talents_gig .gigs_card_wrapper .gigs_card .copy_landing {
    padding-left: .8rem;
    margin-top: 2.5rem;
    display: flex;
    flex-flow: row wrap;
    align-items: baseline;
    list-style-type: none;
}
@media (max-width: 600px){
    .talents_gig .gigs_card_wrapper .gigs_card .copy_landing {
        margin-top: 0rem;
    }
    .talents_gig .gigs_card_wrapper .gigs_card {
        margin-top: 20px;
    }
}



.projects_button--back{
    color: #FFFFFF;
    border-radius: 25px;
    border: none;
    font-size: .87rem;
    padding: 10px;
    background: var(--deepBlue-background);
}


.disabling{
    background-color: rgb(220, 236, 250);
    color: #FFFFFF;
    border-radius: 25px;
    border: none;
    font-size: .87rem;
    padding: 10px;
}
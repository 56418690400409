/* Import css variables */
@import '../../../../variables.styles.css';
.message_title {
  /* width: 90%;
  margin: 0 auto; */
  width: 100%;
}
.message_title h3 {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  color: var(--deepBlue-background);
  /* margin-left: 8%; */
}
.message_title .message_title-search {
  /* margin-left: 8%; */
}
.message_title .message_title-search select {
  width: 320px;
  height: 50px;
  left: 299px;
  top: 189px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.16);
  box-sizing: border-box;
  border-radius: 54px;
  outline: none;
  padding-left: 0.87rem;
}
.message_title .message_title-search select option {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.66);
  /* margin-left: 1rem; */
}

/* Import css variables */
@import "../../../../../variables.styles.css";

.edit-desc-scope{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin-top: 24px;
}
.edit-desc-scope p{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin-left: 1%;
}
.edit-desc-scope button[type="button"]{
    width: 86px;
    height: 30px;
    background: #020079;
    border-radius: 25px;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
    border: none;
    margin-right: 22%;
}

.desc-content-scope {    
    max-width: 521px;
    height: auto;
    margin-left: 1.2%;
    margin-top: 1%!important;
    background: #ADD8E6;
    border: 1px solid rgba(0, 0, 0, 0.18);
    box-sizing: border-box;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 2% 0% 6% 1.2%;
}
.desc-content-scope h5 {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 24px;
    font-feature-settings: 'liga' off, 'calt' off;
    color: rgba(0, 0, 0, 0.8);

}
.desc-content-scope .form-group-des{
    margin-left: -2.7rem!important;
}
.desc-content-scope .form-group-des label{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.8);
}
.desc-content-scope .form-group-des .additonal{
    margin-left: .6rem!important;
}
.desc-content-scope .form-group-des select{
    max-width: 169px;
    height: 36px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.37);
    box-sizing: border-box;
    border-radius: 25px;
    margin-left: 2%;
}
.desc-content-scope .form-group-des .currency-field {
    max-width: 100px
}

.desc-content-scope .form-group-des .extra-select{
    margin-left: 9%!important;
}
.desc-content-scope .form-group-des{
    margin: 5% 5% .5% 5%;
}

.desc-content-scope .but{
    margin-left: -8.1%;
}
.but-switch {

}
.but-switch button[type="button"]{
    min-width: 151px;
    height: 48px;
    background: #FFFFFF;
    border: 1px solid #020079;
    box-sizing: border-box;
    /* Button/secondary */    
    border-radius: 25px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: #020079;
}
.but-switch button[type="submit"]{
    max-width: 151px;
    height: 48px;
    background: #020079;
    border-radius: 25px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;  
    color: #FFFFFF;
    margin-left: .4rem;
}
.desc-content-scope .but button[type="submit"]{
    max-width: 116px;
    height: 45px;
    background: #020079;
    border-radius: 25px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;  
    color: #FFFFFF;
    margin-left: .4rem;
}
.desc-content-scope .but button[type="button"]{
    min-width: 116px;
    height: 45px;
    background: #FFFFFF;
    border: 1px solid #020079;
    box-sizing: border-box;
    /* Button/secondary */    
    border-radius: 25px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: #020079;
}

.indicator-extraservice {
    margin-left: -26rem!important;
    margin-bottom: -1.7rem;
}

/* THE EXTRAS RESULT */
.result-extras {
    display: flex;
    font-family: var(--font-family);
    flex-flow: row wrap;
    align-items: center;
    margin-left: 1%;
}
.result-extras h6 {
    font-size: 1.2rem;
    font-weight: 590;  
}
.result-extras p {
    font-size: .9rem;
    margin-left: .5rem;
    margin-top: .8rem;
}
.revision-line{
    margin-left: 1%;
    max-width: 550px;
    margin-top: 1%;
}
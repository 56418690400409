/* Import css variables */
@import "../../../../../variables.styles.css";

/* LEFT */

section .overview-cont .overview-wrapper .left-side
.left-card-image {
    width: 90%;
    height: 48vh;
}

section .overview-cont .overview-wrapper
 .left-side .gig-category .cate-divider {
    width: 24px;
    height: 24px;
    color: var(--deepBlue-background);
 }

 section .overview-cont .overview-wrapper
 .left-side .gig-category p {
    color: var(--deepBlue-background);
 }

 section .overview-cont .overview-wrapper
 .left-side .gig-category p:nth-child(1) {
     font-weight: 600;
 }


section .tab-wrapper .overview-cont .overview-wrapper .left-side 
.review-slider .rating-card{
    width: 90%;
    height: auto!important;
    padding: 1rem;
}

section .tab-wrapper .overview-cont .overview-wrapper
.right-side .right-gigcontent{
    width: 80%;
    height: auto;
    /* padding: 1rem; */
    padding-bottom: 2%;
}

 .basic-content {
    margin-left: 1.3rem!important;
}
.basic-content .heading_copy {
    margin-top: 1rem;
}
 .basic-content .heading_copy div {
     width: 4rem;
     height: 4rem;
     margin-right: 1.3rem;
     border-radius: 360px;
     background: var(--lightBlue);
     padding: 1.4rem .4rem;
     color: #FFFFFF;
     font-size: .8rem;
     font-weight: 500;
 } 
 .basic-content li {
     list-style-type: none;
 }
 .basic-content li img {
     width: 17px;
     height: 17px;
 }
 .basic-content li p {
     margin-left: .8rem;
 }
 /* .basic-content button[type="button"]{
     
 } */
/* Reviews */
 section .overview-cont .overview-wrapper 
.left-side .reviews h5 {
    font-size: 1.2rem!important;
    font-weight: 590;
    color: var(--light-color-text);
}
section .overview-cont .overview-wrapper 
.left-side .reviews .review-slider .rating-card {
    width: 90%;
    height: auto;
    padding: 1rem;
}

/* CAROUSEL */
section .overview-cont .overview-wrapper 
.left-side .reviews .review-slider .rating-card 
.carous-img {
    width: 5rem;
    height: 5rem;
}
section .overview-cont .overview-wrapper 
.left-side .reviews .review-slider .rating-card .carousel-control-prev-icon{
    background: blue!important;
}

section .overview-cont .overview-wrapper 
.left-side .reviews .review-slider .rating-card .card_container
.prof_img{
    width: 3rem;
    height: 3rem;
}
section .overview-cont .overview-wrapper 
.left-side .reviews .review-slider .rating-card .card_container
.card_container--copy .star_img {
    width: 1.1rem;
    height: 1.1rem;
    margin-left: .3rem;
}

section .overview-cont .overview-wrapper 
.left-side .reviews .review-slider .rating-card .card_container
.card_container--copy .card_container--copyWrapper h6{
    font-weight: 600;
}

section .overview-cont .overview-wrapper 
.left-side .reviews .review-slider .rating-card .card_container
.card_container--copy{
    margin-left: 1rem;
}

section .overview-cont .overview-wrapper 
.left-side .reviews .review-slider .rating-card .card_container
.card_container--copy .card_container--copyWrapper p[data="country"]{
    color: var(--deepBlue-background);
    margin-left: .3rem;
}

section .overview-cont .overview-wrapper 
.left-side .reviews .review-slider .rating-card .card_container
.card_container--copy .card_container--copyWrapper p[data="star_no"]
{
    margin-left: .3rem;
    color: orange;
    font-weight: 590;
}

section .overview-cont .overview-wrapper 
.left-side .reviews .review-slider .rating-card .card_container
.card_container--copy p[data="body_cop"]{
    font-size: .95rem;
}

section .overview-cont .overview-wrapper 
.left-side .reviews .review_button{
    width: 9rem;
    height: 6vh;
    border: none;
    outline: none;
    background: var(--deepBlue-background);
    border-radius: 25px;
    font-size: .8rem;
    color: #fff;
    margin-top: .7rem;
    margin-right: 9%;
}
section .overview-cont .overview-wrapper 
.right-side .contact_button{
    width: 401px;
    height: 44px;
    background: #020079;
    border-radius: 25px;
    font-style: normal;
    font-weight: 600;
    font-size: .9rem;
    line-height: 24px;
    border: none;
    outline: none;
    color: #FFFFFF;
    margin-top: .8rem;
} 

@media (max-width: 600px){
    section .overview-cont .overview-wrapper 
.right-side .contact_button{
    margin-bottom: 10px;
}
section .overview-cont .overview-wrapper 
.left-side .reviews .review_button{
    margin-bottom: 15px;
    font-size: 18px;
    width: 170px;
    height: 57px;
}
h6{
    font-size: 20px;
}
section .overview-cont .overview-wrapper 
.right-side .contact_button{
    font-size: 20px;
}
}
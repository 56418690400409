/* Import css variables */
@import "../../variables.styles.css";

.gig-input {
    margin-top: 1rem;
}

.gig-input input{
    /* min-width: 458px; */
    /* width: 98%!important; */
    min-width: 521px!important;
    height: 48px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    border-radius: 25px; 
    padding-left: 1rem;
    outline: none;
}
.gig-input label{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #020079;
}

@media (max-width: 600px) {
    .gig-input input{
        min-width: 321px!important;
    } 
}

/* Import css variables */
@import "../../../variables.styles.css";

section {   
    width: 100%;
    }
section .body-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.head h1 {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 60px;
    font-feature-settings: 'liga' off;
    color: var(--deepBlue-background);
    text-align: center;
}
.head h6{
    text-align: center;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 24px;
    font-feature-settings: 'liga' off, 'calt' off;
    color: rgba(2, 0, 121, 0.65);
}
.indicator-container-1, .indicator-container-2{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 2rem;
    align-items: center;
}
/* .indicator-container-1 div img{
    margin-top: -2rem;
} */
.indicator-container-1 div:nth-child(2)
{
    margin-left: 4%;
}
.indicator-container-2 div:nth-child(2){
    margin-left: 9%;
    margin-top: .2rem;
}
.indicator-container-1 div p, 
.indicator-container-2 div p{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;   
    text-align: left;
    color: rgba(14, 14, 14, 0.5);
}
.indicator-container-1 div img, 
.indicator-container-2 div img{
    margin-top: -2rem;
}
.sub-heading{
    display: flex;
    flex-direction: column;
    justify-content: left;
}
.sub-heading p {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 24px;
    font-feature-settings: 'liga' off, 'calt' off;
    color: rgba(0, 0, 0, 0.5);
    margin-left: 5.6%;
}
/* section .body-wrapper .sub-heading .profile-line {
    margin-left: -.1rem;
} */
.sub-heading .profile-line{
    min-width: 510px;
    margin-top: -.3rem;
    /* margin-left: .2%; */
}
.form-group-update{
    margin-top: 1rem;
}
.form-group-update select{
    min-width: 521px!important;
    height: 48px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    border-radius: 25px; 
    padding-left: 1rem;
    outline: none;
    padding-right: 1rem;
}
.form-group-update label{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */
    color: #020079;
}
.sex {
    margin-top: 1rem;
}
.buttons{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin-top: 2rem;
}
.buttons button[type="submit"]:nth-child(1){
    min-width: 151px;
    height: 48px;
    background: #FFFFFF;
    border: 1px solid #020079;
    box-sizing: border-box;
    border-radius: 25px;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #020079;
    margin-left: .5rem;
}
.buttons button[type="submit"]:nth-child(2){
    width: 151px;
    height: 48px;
    background: var(--deepBlue-background);
    border: 1px solid #020079;
    box-sizing: border-box;
    border-radius: 25px;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    margin-left: .5rem;
    margin-top: 0;
}
.buttons button[type="button"]:nth-child(1){
    min-width: 151px;
    height: 48px;
    background: #FFFFFF;
    border: 1px solid #020079;
    box-sizing: border-box;
    border-radius: 25px;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #020079;
    margin-left: .5rem;
}
.buttons button[type="button"]:nth-child(2){
    min-width: 151px;
    height: 48px;
    background: var(--deepBlue-background);
    border: 1px solid #020079;
    box-sizing: border-box;
    border-radius: 25px;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    margin-left: .5rem;
}
.address-status{
    /* display: none; */
}

/* Address and status form style */
.address-form {
    margin-left: -6.5%;
}
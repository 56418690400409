/* Import css variables */
@import "../../../variables.styles.css";

section {
    margin-top: 2rem;
}
section .title {
    position: relative;
}
section .title img {
    position: absolute;
    left: 43%;
    cursor: pointer;
}
section .top-cards-wrapper .title h2{
    margin-left: 6%;
    font-size: 1.5rem;
    font-family: var(--font-family);
    color: var(--deepBlue-background);
}

section .top-cards-wrapper .card-wrapper-gig {
    /* display: grid!important; */
    /* grid-template-columns: repeat(4,1fr)!important; */
    /* display: flex!important;
    flex-flow: row wrap!important; */
    margin-left: 5%;
}

section .top-cards-wrapper .card-wrapper-gig .card-group {
    width: 32%;
    height: 100%;
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    margin-top: .9rem;
    margin-left: .7rem;
}

section .top-cards-wrapper .card-wrapper-gig .card-group a .img-card {
    /* width: 100%!important; */
    height: 150px!important;
    object-fit: cover!important;
    cursor: pointer;
}



section .card-group .image_wrapper {
    width: 100%;
    cursor: pointer!important;
}

section .card-group .image_wrapper .img-card {
    width: 100%;
    /* height: fit-content; */
    /* height: ; */
}

section .card-group .card-copy{
    position: relative;
    margin-top: -5rem;
}
section .card-group .card-copy .edit-delete {
    margin-top: 27%;
    margin-left: .3rem;
    position: absolute;
}
section .card-group .card-copy .edit-delete img{
    width: 1.1rem;
    height: 1.1rem;
    cursor: pointer;
    margin-top: 1.8rem;
}
section .card-group .card-copy .edit-delete img:nth-child(2){
    margin-left: .2rem;
}
section .card-group .card-copy h5{
    font-size: .9rem;
    margin-left: 1rem;
    font-family: var(--font-family);
    font-weight: 600;
    margin-top: 1rem;
}
section .card-group .card-copy p{
    float: right;
    margin-left: 3rem;
    /* margin-top: 4.0rem; */
    margin-top: 5.0rem;
    font-family: var(--font-family);
    font-weight: 590;
    color: var(--deepBlue-background);
    font-size: .8rem;
}
section .card-group .card-copy p span {
    font-size: .95rem;
}
/* Import css variables */
@import "../../../variables.styles.css";

.bid_description{

}
.bid_description h4 {
 color: var(--lightBlue)!important;
}
.bid_list_text h4{
    font-size: 1.1rem !important;
    /* padding: 2.0rem 0 !important; */
    margin-top: 3%;
}
.table_wrapper{
    width: 95% !important;
    margin: auto !important;
}
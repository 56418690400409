@import '../../../../variables.styles.css';
.modal-header{
    background-color: var(--deepBlue-background);
}

.modal-header .btn-close{
    color: #fff ;
}

.modal-body1{
    background-color: rgb(243, 241, 241);
    width:100%;
}
.form-update1 {
    width:100%;
}
.form-group-update1{
    margin-top: 1rem;
    width: 100%;
}
.modal__description{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.modal__title{
    font-size: 1.2rem;
    font-weight: 500;
}

.modal__title span{
    color: var(--hero-section-color) !important;
   font-weight: 600;
   padding-right: 0.5rem
}

.modal__details{
    padding: 1rem 2rem;
    width: 94%;
    background-color: rgb(224, 220, 220);
    margin-left: auto;
}
.modal__details p{
    padding: 0;
    margin: 0;
    /* color: var(--light-color-text); */
  color: #353535;
}

.modal__activity-title{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modal__activity-button button{
    width: 10rem;
    padding: 0.5rem 1rem;
    outline: none;
    border: none;
    background-color:  rgb(224, 220, 220);
    border-radius: 7px;
    color: var(--hero-section-color);
   font-weight: 600;
    font-size: 1rem;
}

.modal__detail-content{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2rem auto;
    width: 60%;
}

.modal__detail-left, .modal__detail-right{
width: 49%;

}

.modal__detail-left p{
color: #242323;
font-weight: 500;
line-height: 2rem;
font-size: 1rem;
}
.modal__detail-right p{
    color: #353535;
    font-weight: 600;
    line-height: 2rem;
    font-size: 1rem;
}

.modal__form{
margin: 2rem 0;
background-color: #fff;
padding: 0.5rem 1rem;
box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

}

.modal__form form{
    width: 100%;
    margin: 0;
}
.modal__form textarea{
    width: 100%;
    
    outline: none;
    border: none;
}

.modal__form-option{
    display: flex;
    justify-content:space-between;
    align-items: center;
}

.modal__form-submit button{
    width: 8rem;
    padding: 0.5rem 1rem;
    outline: none;
    border: none;
    background-color:  rgb(224, 220, 220);
    border-radius: 7px;
    color: var(--hero-section-color);
   font-weight: 600;
    font-size: 1rem;
}

.modal__form-attachment input{
    display: none;
}
/* Import css variables */
@import "../../../../variables.styles.css";

/* latest */

.messagehunter{
    display: flex;
    flex-direction: column;
    height: 100vh;
    
}
.messagehunter .messagehunter__container{
    display: flex;
    flex-direction: column;
    height: 30rem;
    padding-top: 1rem;
    overflow-y: auto;
}

.messagehunter__container .messagehunter__sender{
    border: 2px solid rgba(0, 128, 0, 0.75);
    border-radius: 2rem 2rem 0 2rem;
    width: 90%;
    padding: 1rem 1.2rem;
    align-self: flex-end;
    word-wrap: break-word;
    /* height: 4rem; 
    max-height: 15rem;  */
    margin-bottom: 1.5rem;
    background-color: #fff;

}

.messagehunter__container .messagehunter__receiver{
    border: 2px solid #BF5700;
    border-radius: 2rem 2rem 0 2rem;
    width: 90%;
    padding: 1rem 1.2rem;
    align-self: flex-start;
    word-wrap: break-word;
     /* height: 4rem; 
    max-height: 15rem;  */
    margin-bottom: 1.5rem;
    background-color: #fff;

}
.messagehunter__text{
    width: 100%;
    margin-bottom: auto;
    position: relative;
}

.messagehunter__text textarea{
    width: 100%;
    position: absolute;
    bottom: -8rem;
}

.messagehunter__sendicon button{
    outline: none;
    border: none;
    text-align: center;
    background-color: #BF5700;
    border-radius: 2rem;
    padding: 0.5rem 3rem;
   

}
 .messagehunter__sendicon{
    /* background-color: #BF5700;
    border-radius: 2rem;
    padding: 1rem; */
    position: absolute;
    right: 2rem;
    bottom: -7rem;
}

.messagehunter__sendicon svg{
        font-size: 1.5rem;
        color: #fff;
   
}
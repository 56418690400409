.viewpastjobs_button{
    border: none;
    width: 30%;
    height: 48px;
    background: #020079;
    border-radius: 25px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    margin-left: 35%;
    margin-bottom: 10px;
}
.talent_bars{
    display: flex;
    width: 90%;
    margin-left: 5%;
    justify-content: center;
}
.talent-bars .talent-bars-column{
    width: 35% !important;
}
.talent-videos .talent-videos-desc{
    border: none;
    padding: 8px 12px;
    background-color: green;
    color: white;
    border-radius: 8px;
}
.image_list{
    width: 27%;
    margin-left: 3%;
}
@media (max-width: 900px){
    .talent_bars{
        flex-direction: column;
    }
    .image_list{
        width: 100%;
    }
}
@media (max-width: 600px){
.viewpastjobs_button{
    width: 70%;
    margin-left: 15%;
}
}
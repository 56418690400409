.widthdrawl{
    display: flex;
    justify-content: space-between;
}
.form-updated_hunt{
    margin-left: 0;
}
.withdrawal_bank{
    width: 100%;
    outline: none;
    border-color: gray;
    border-radius: 5px;
    padding:  5px;
    margin-bottom: 10px;
}
.withdrawl__left{
    width: 50%;
}
.withdrawl__right{
    width: 50%;
}
.withdrawal_acc{
    width: 100%;
    outline: none;
    border: 1px solid  gray;
    border-radius: 5px;
    padding: 5px;
}
.withdrawal_pnd{
    margin-top: 20px;
}
@import '../../../variables.styles.css';

.earning {
  background-color: rgb(252, 250, 250);
  padding: 2rem;
}
/* heading */
.earning__head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}
.modal.show .modal-dialog {
  transform: none;
  max-width: 600px !important;
}
.earning__summarycontainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 1rem;
  background-color: #fff;
  padding: 1.5rem;
  border: 1px solid rgb(211, 209, 209);
  margin-bottom: 2rem;
}

.earning__summary {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* min-width: 20rem;   */
}

.earning__summary:not(:last-child) {
  border-right: 1px solid rgb(211, 209, 209);
}
p {
  margin: 0;
  padding: 0;
}
.earning__summary--title h6 {
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 1rem;
}
.earning__summary--value p {
  font-size: 1.2rem;
  color: rgba(0, 0, 0, 0.9);
}
.earning__statement,
.earning__show {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  box-sizing: border-box;
}

.earning__statement--left,
.earning__show--left {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  /* width: 60%; */
  width: 74%;
}
.earning__statement--right,
.earning__show--right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem 0;
  /* width:30%; */
  width: 25%;
}
.earning__statement--title,
.earning__show--title {
  /* width: 15%;  */
  width: 10%;
}
.earning__statement--withdrawl-btn,
.earning__show--filtering {
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
  /* width: 75%; */
  width: 88%;
}

.filtering {
  width: 10rem;
}
.filtering select {
  width: 100%;
  padding: 0.5rem;
  outline: none;
  background-color: #fff;
  border: 1px solid rgb(211, 209, 209);
  color: rgba(0, 0, 0, 0.5);
}

.withdrawlbtn span {
  margin-right: 0.3rem;
  color: rgba(0, 0, 0, 0.9);
}

.withdrawlbtn button {
  padding: 0.5rem;
  outline: none;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid rgb(211, 209, 209);
  border-radius: 5px;
  color: rgba(0, 0, 0, 0.5);
}
.earning__show--right button {
  padding: 0.5rem;
  cursor: pointer;
  background-color: transparent;
  outline: none;
  border: none;
  color: var(--deepBlue-background);
}

.earning__statement--right button {
  color: #fff;
  background-color: rgba(2, 0, 121, 0.65);
  outline: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem 0.7rem;
  font-size: 14px;
  border-radius: 5px;
  font-weight: 500;
}

/* table */
.earning__table {
  overflow-x: auto;
}
.earning__table table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid rgb(211, 209, 209);
  background-color: #fff;
}

.earning__table th {
  background-color: #fff;
  padding: 0.2rem 1rem;
  color: rgba(0, 0, 0, 0.5);
}
.earning__table th:last-child,
td:last-child {
  text-align: start;
}

.earning__table td {
  padding: 0.7rem 1rem;
  color: rgba(0, 0, 0, 0.9);
}

.earning__table tr {
  border-bottom: 1px solid rgb(211, 209, 209);
}

@media screen and (max-width: 902px) {
  .earning__statement,
  .earning__show {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
    box-sizing: border-box;
  }
  .earning__statement--left,
  .earning__show--left {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid rgb(211, 209, 209);
    background-color: #fff;
  }

  .earning__table th {
    background-color: #fff;
    padding: 0.2rem 1rem;
    color: rgba(0, 0, 0, 0.5);
  }
  .earning__table th:last-child,
  td:last-child {
    text-align: start;
  }

  .earning__table td {
    padding: 0.7rem 1rem;
    color: rgba(0, 0, 0, 0.9);
  }

  .earning__table tr {
    border-bottom: 1px solid rgb(211, 209, 209);
  }

  @media screen and (max-width: 902px) {
    .earning__statement,
    .earning__show {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 1rem;
      box-sizing: border-box;
    }
    .earning__statement--left,
    .earning__show--left {
      width: 100%;
    }
    .earning__statement--right,
    .earning__show--right {
      width: 100%;
      justify-content: center;
    }
    .earning__statement--left,
    .earning__show--left {
      display: flex;
      flex-direction: column;
    }
    .withdrawlbtn,
    .filtering {
      width: 100%;
    }
    .earning__statemet--title,
    .earning__show--title {
      align-self: center;
    }
    /*.earning__statement--withdrawl-btn, .earning__show--filtering{
        width:88%
    } */
    .withdrawlbtn button {
      width: 100%;
    }
  }

  @media screen and (min-width: 1026px) {
    .earning__statement--left,
    .earning__show--left {
      width: 60%;
    }
    .earning__statement--right,
    .earning__show--right {
      width: 30%;
    }
    .earning__statemet--title,
    .earning__show--title {
      width: 15%;
    }
    .earning__statement--withdrawl-btn,
    .earning__show--filtering {
      width: 75%;
    }
  }
  @media (max-width: 600px) {
    .withdrawlbtn button {
      width: 100%;
    }
    .earning__statement--right,
    .earning__show--right {
      width: 100%;
      margin: auto;
    }
    .earning__statement--right button {
      width: 100%;
    }
  }
}


/* Import css variables */
@import "../../../variables.styles.css";



/* .talent_card_wrapper .me_card_wrapper-content {
    width: 88%;
    height: 140px;
    margin-left: 8%;
    margin-top: 2rem;
    border: 1px solid rgba(0, 0, 0, 0.13);
    box-sizing: border-box;
    border-radius: 5px;
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
    padding-left: 1rem;
    padding-right: 1rem;
    cursor: pointer;
}
.me_card_wrapper-content .me_card_wrapper-content--subwrapper{
     
     margin-left:1rem !important;
         

}
.me_card_wrapper-content .me_card_wrapper-content--subwrapper h6{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    font-feature-settings: 'liga' off, 'calt' off;
    color: #0C0048;
}

.me_card_wrapper-content .me_card_wrapper-content--subwrapper h3{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #0C0048;
    margin-left: 1%;
}
.me_card_wrapper-content .me_card_wrapper-content--subwrapper p {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: rgba(12, 0, 72, 0.67);
}
.me_card_wrapper .me_card_wrapper-content img {
    width: 4.5rem;
    height: 4.5rem;
}

.talent__left{
display:flex
}
.talent__right{

} */


.messages{
    display: flex;
    justify-content: space-between;
    margin: 0;
    height: 100%;

    /* height: 100vh; */
    position: relative; 
}
.nomessage{
    margin: 10rem 0;
    font-size: 1.5rem;
}
.messagestalent__listcontainer{
    /* width: 45%; */
    width: 35%;
    padding-top: 0.5rem;
    display: flex;
    flex-direction: column;
    /* padding:2rem  0;  */
    /* border-right: 1px solid #C4C4C4; */
    overflow-y: scroll;
    margin: 0;
}


.messages__form{
    width: 100%;
    position: relative;
}



.messages__form input{
    width: 100%;
    border: none;
    padding: 0.5rem;
    outline: none;
    border-bottom: 1px solid #C4C4C4;
    margin-bottom: 1.5rem;
}

.messages__form span{
    position: absolute;
    left: 21rem;
    top: 0.5rem
}

.messages__list ul{
    
    list-style: none;

}
 
.messages__item{
    width: 100%;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin-bottom: 2rem;
}
.messages__item:hover{
    background-color: #f8f6f6;
}

.messages__itemcontent{
    display: flex;
    justify-content: space-between;
    width: 55%;
    /* width: 65%; */
    align-items: center;
}
.messages__userimage{
width: 20%;
background-color: #C4C4C4;
border-radius: 50%;
height:3rem;
display: flex;
justify-content: center;
align-items: center;
}

.messages__preview{
width: 75%;
}
.messages__date{
    /* width: 28%; */
    width: 40%;

}

/* message content */
.messagestalent__conversationcontainer{
    /* width: 55%; */
    width: 65%;
    min-height: 100vh;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 0 !important;
}

.messages__content{
  margin: 0 auto;
  padding: 7rem 2rem; 
}


.messages__content svg{
    font-size: 5rem;
    
}

.messageconversation__text {
    
    width: 100%;
    margin-bottom: auto;
    /* position: relative;  */
    position: sticky;
    bottom: 0;
    margin: 1rem 0 0 0;    
}

.messageconversation__sendicon{
        position: absolute;
        right: 2rem;
        bottom: 1rem;
        background-color: #BF5700;
        border-radius: 2rem;
        
}

.messageconversation__sendicon button{
 padding: 0.5rem;
 outline: none;
 background-color: transparent;
 border: none;
 width: 6rem;
 
}

.messageconversation__sendicon svg{
    font-size: 1.5rem;
    color: #fff;
}


.messageconversation__text textarea{ 
    padding: 1rem; 
    width: 100%;
    /* position: sticky;
    bottom: 0;
    margin: 1rem 0 0 0; */
}

.messageconversation__container{
    width: 100%;
    display: flex;
    flex-direction: column;  
    overflow-y: auto;
   
}
.messageconversation{
    width: 100%;
    
}


.messageconversation__receiver{
    border: 2px solid var(--lightOrange);
    border-radius: 0 2rem 2rem 2rem;
    width: 80%;
    padding: 1rem 1.2rem;
    min-height: 4rem;
    align-self: flex-start;
    margin-bottom: 1.5rem;
    background-color: #fff;

}

.messageconversation__sender{
    border: 2px solid  rgba(0, 128, 0, 0.75);
    border-radius: 2rem 2rem 0 2rem;
    width: 80%;
    padding: 1rem 1.2rem;
    align-self: flex-end;
    min-height: 4rem;
    margin-bottom: 1.5rem;
    background-color: #fff;
}

.messageconversation__sender p{
    margin: 0;
    font-size: 1rem;
}
form .form-group-des select{
    width: 98%;
    height: 44px;    
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.47);
    box-sizing: border-box;
    border-radius: 25px;
    padding-left: 1rem;
    padding-right: 1rem;
    outline: none;
}
form .sel label{
    margin-top: -14rem;
}
.jobmilestone_number{
    color: #020079;
    font-weight: 600;
    font-size: 18px;
}
.jobmilestone_file{
    color: #020079;
    font-size: 14px;
}
.milestone_whole{
    display: flex;
    justify-content: space-between;
}
.jobmilestone_input{
    width: 250px;
    margin-bottom: 10px;
}
.modalmilestone{
    display: flex;
    flex-direction: column;
}
.jobmilestone_icon{
    font-size: 15px;
    margin-top: 8px;
}
.uploaded_milestone{
    display: flex;
    width: 50%;
    justify-content: center;
    /* justify-content: space-around; */
    align-items: center;
}
.milestone_imgplaceholder{
    width: 200px;
    height: 70px;
}
.accordion-button:focus {
    z-index: 3;
    border-color: none !important;
    outline: 0;
    box-shadow: none !important;
}
.milestone__submission{
    display: flex;
    flex-direction: column;
}
.jobmilestone_titleinput{
    padding:  5px;
    outline: none;
    margin-bottom: 10px;
    width: 90%;
}
.jobmilestone_commentinput{
    padding:  5px;
    width: 90%;
    outline: none;
}
.jobmilestone_input{
    display: none;
}
.jobmilestone_label{
    color: grey;
    padding: 5px 8px;
    border-radius: 20px;
    border: 1px solid grey;
    margin-bottom: 5px;
}
.jobmilestone_submit{
    background-color: #020079;
    color: white;
    padding: 5px 8px;
    border-radius: 20px;
    border: none;
    font-size: 13px;
}
.milestone_part1{
    width: 100%;
}
.jobmilestone_sec1{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
@media (min-height: 400px){
    .modal-body{
        overflow-y: auto !important;
    }
}
/* Import css variables */
@import "../../../variables.styles.css";

.trustus_wrapper {
    width: 100%;
    height: auto;
    padding-bottom: 2rem;
    background: #ADD8E6;
    margin-top: 10rem;
}
.trustus_wrapper .trustus_wrapperTop {
    display: flex;
    flex-flow: row wrap;
    padding: 100px 5rem 2rem 5rem;
    justify-content: space-between;
    margin-top: 10rem;
   
}

.trustus_wrapper .trustus_wrapper__left {

}

.trustus_wrapper .trustus_wrapperTop .trustus_wrapper__left h1 {
    /* font-family: 'Bahnschrift'; */
    width: 60%;
    height: 120px;
    font-style: normal;
    font-weight: 700;
    font-size: 44px;
    line-height: 60px;
    font-feature-settings: 'liga' off;
    color: #020079;
}


/*Top RIGHT */
.brandimg{
    width: 100%;
}
 @media (max-width: 400px){
    .trustus_wrapper .trustus_wrapperTop {
        padding: 100px 5rem 2rem 3rem;
    }
 }
.trustus_wrapper .trustus_wrapper__right
 .trustus_wrapper__copy {
    max-width: 345px;
    min-height: 113.45px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000; 
    border-left-style: solid;
    border-left-color: var(--deepBlue-background);
    padding-left: .9rem;
 }

 /* BOTTOM */
 .bottom_copytalent {
     width: 100%;
     display: flex;
     flex-flow: row wrap;
     justify-content: space-between;
     padding: 0 8% 0 6%;
 }
 /* left */
 .bottom_copytalent .bottom_copytalent--left li {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-end;
}

.bottom_copytalent .bottom_copytalent--left li h6 {
    font-style: normal;
    font-weight: 700;
    font-size: 1.3rem;
    line-height: 24px;
    color: #020079;
    align-self:flex-end;
    margin-left: 1rem;
}

.bottom_copytalent .bottom_copytalent--left p {
    max-width: 34rem;
    height: 18rem;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 32px;
    color: #000000;
    margin-left: 11%;
}

.bottom_copytalent .second {
    margin-top: -8rem;
}

/* Right */
 .trustus_wrapper .button_bottomtrust {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-right: 8%;
    margin-top: -8%;
 }

 .trustus_wrapper .button_bottomtrust button[type="button"]{
    width: 170px;
    height: 50px;
    background: var(--deepBlue-background);
    border-radius: 30px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: .88rem;
    line-height: 24px;
    color: #FFFFFF;
    border: none;
 }

 @media (max-width: 600px){
    .trustus_wrapper .trustus_wrapperTop .trustus_wrapper__left h1 {
        margin-bottom: 200px;
    }
    .bottom_copytalent .bottom_copytalent--left p {
        margin-bottom: 70px;
    }
    .trustus_wrapper .button_bottomtrust button[type="button"]{
        margin-top: 40px;
    }
 }

.view_icon_payment{
  font-size: 2rem;
  text-align: left;
}


.rating-body_wrapper {
  width: 80% !important;
  margin: auto;
  padding: 1rem;
  /* border: solid 1px #ccc; */
  border-radius: 8px;
}
.rating_select {
  width: 98%;
  height: 48px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  border-radius: 25px;
  padding-left: 1rem;
  outline: none;
}
.form_job-rating {
  margin-top: 1rem;
  color: #000000cc;
  font-weight: 600;
}
.rating-style {
  color: red !important;
  font-size: 20px !important;
}


/* Import css variables */
@import '../../../../variables.styles.css';

.messages{
    display: flex;
    justify-content: space-between;
    margin: 0;
    height: 100vh;
   
}
.nomessage{
    margin: 10rem 0;
    font-size: 1.5rem;
}
.messages__listcontainer{
    /* width: 45%; */
    width: 40%;
    display: flex;
    flex-direction: column;
    padding:2rem  0;
    border-right: 1px solid #C4C4C4;
    overflow-y: scroll;
    margin: 0;
}


.messages__form{
    width: 100%;
    position: relative;
}



.messages__form input{
    width: 100%;
    border: none;
    padding: 0.5rem;
    outline: none;
    border-bottom: 1px solid #C4C4C4;
    margin-bottom: 1.5rem;
}

.messages__form span{
    position: absolute;
    left: 21rem;
    top: 0.5rem
}

ul.messages__list{
    width: 100%;
    list-style: none;
    padding:0;
    padding-left: 0.7rem;

}
 
.messages__item{
    width: 100%;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    margin-bottom: 2rem;
}
.messages__item:hover{
    background-color: #f8f6f6;
}

.messages__itemcontent{
    display: flex;
    justify-content: space-between;
    /* width: 55%; */
    width: 60%;

    align-items: center;
}
.messages__userimage{
width: 20%;
background-color: #C4C4C4;
border-radius: 50%;
height:3rem;
display: flex;
justify-content: center;
align-items: center;
}

.messages__preview{
/* width: 75%; */
padding: 0 0.3rem;
}
.messages__date{
    /* width: 28%; */
    width: 40%;

}
.messages__date p{
    width: 100%;
}

/* message content */
.messages__conversationcontainer{
    /* width: 55%; */
    width: 60%;
    min-height: 100vh;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 0 !important;
}

.messages__content{
  margin: 0 auto;
  padding: 7rem 2rem; 
}


.messages__content svg{
    font-size: 5rem;
    
}

.messageconversationhunter__text {
    width: 100%;
    margin-bottom: auto;
    position: relative;  
}

.messageconversation__sendicon{
        position: absolute;
        right: 2rem;
        bottom: 1rem;
        background-color: #BF5700;
        border-radius: 2rem;
        
}

.messageconversation__sendicon button{
 padding: 0.5rem;
 outline: none;
 background-color: transparent;
 border: none;
 width: 6rem;
 
 
}

.messageconversation__sendicon svg{
    font-size: 1.5rem;
    color: #fff;
}


.messageconversationhunter__text textarea{ 
    padding: 1rem;
    width: 100%;
    position: sticky;
    bottom: 0;
    margin: 1rem 0 0 0;
}

.messageconversationhunter__container{
    width: 100%;
    display: flex;
    flex-direction: column;  
    overflow-y: auto;
   
}
.messageconversation__hunter{
    width: 100%;
}


.messageconversationhunter__receiver{
    border: 2px solid var(--lightOrange);
    border-radius: 0 2rem 2rem 2rem;
    width: 80%;
    padding: 1rem 1.2rem;
    height: 4rem;
    align-self: flex-start;
    margin-bottom: 1.5rem;
    background-color: #fff;

}

.messageconversationhunter__sender{
    border: 2px solid  rgba(0, 128, 0, 0.75);
    border-radius: 2rem 2rem 0 2rem;
    width: 80%;
    padding: 1rem 1.2rem;
    align-self: flex-end;
    height: 4rem;
    margin-bottom: 1.5rem;
    background-color: #fff;

}

.check_box {
  width: 5% !important;
  height: 12px !important;
}

.check_wrapper {
  font-size: 12px;
  margin-top: 1rem;
  display: flex;
  /* align-items: center; */
}
.dsc-title {
  width: 80%;
  margin: auto;
}

@import "../../../variables.styles.css";

.freq{
    background: var(--deepBlue-background);
}
.freq_all{
    padding-top: 40px;
    display: flex;
    width: 90%;
    justify-content: space-between;
    margin-left: 5%;
}
.freq_sec2{
    color: white;
    width: 50%;
}
.freq_h2{
    /* color: #020079; */
    color: white;
    width: 65%;
    margin-left: 20%;
}
.plus_side{
    display: flex;
    justify-content: space-between;
}
.plus_side button{
    border: none;
    background-color: white;
    font-size: 20px;
    /* color:  #020079; */
}
.plus_side1{
    padding: 10px 10px;
    border-radius: 10px;
    background-color: white;
    width: 80%;
    margin-left: 10%;
    margin-top: 15px;
}
.plus_word{
    color: black;
}
@media(max-width: 900px){
    .freq_sec2{
        width: 80%;
        margin-left: 10%;
    }
    .freq_h2{
        margin-left: 30%;
    }
}
@media(max-width: 500px){
    .freq_h2{
        margin-left: 10%;
        width: 80%;
    }
    .freq_all{
        flex-direction: column;
    }
    .freq_sec2{
        margin-left: 5%;
        margin-top: 10px;
    }
}
.tab-wrapper {
width: 90%!important;
margin-left: 6%;
}

/* .overview-cont .overview-wrapper .left-side{
    padding-left: -20%!important;
} */

form .switch-button {
    margin-top: 1.8rem;
    margin-left: -3.1%;
}
form .switch-button button[type="button"],
form .switch-button button[type="submit"] {
    width: 9rem!important;
    height: 3rem!important;
}
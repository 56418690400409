/* Import css variables */
@import "../../../../variables.styles.css";

.escrow_wrapper h2 {
    font-weight: 600;
    margin-left: 7%;
    color: var(--deepBlue-background);
    margin-top: 2rem;
}

.escrow_wrapper h4{
    font-size: 1.1rem;
    font-weight: 580;
    color: var(--lightBlue);
    margin-left: 7%;
    width: 50%;
    line-height: 1.7rem;
}
.escrow_wrapper .escrow_details {
    width: 43%;
    height: 50vh;
    margin-left: 7%;
    margin-top: 4rem;
    border-radius: 5px;
    padding: 1rem;
}

.escrow_details h3 {
     color: var(--deepBlue-background);
}

 .escrow_details .escrow_date h6 {
     font-size: 1rem;
     font-weight: 520;
 }

 .escrow_details .price_escrow {
     margin-left: 10%;
     margin-top: 20%;
 }

 .escrow_details .price_escrow span {
     font-size: 1.2rem;
 }

 .escrow_details span {
     margin-left: .5rem;
 }

 .escrow_button button[type="submit"]{
     width: 70%;
     height: 48px;
 }
@media (max-width: 600px){
    .escrow_wrapper .escrow_details {
        width: 85%;
    }
    .escrow_wrapper h4{
    width: 80%
    }
}
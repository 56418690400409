/* Import css variables */
@import "../../../variables.styles.css";

.form-update .form_job label {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.8);
}
.order_button{
    border: none;
    background-color: #020079;
    border-radius: 15px ;
    color: white;
    padding: 8px 15px;
}
.form-update .form_job select {
    width: 98%;
    height: 48px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    border-radius: 25px; 
    padding-left: 1rem;
    outline: none;

}
.form-update .job_button button[type="submit"]{
    width: 97%;
}

.action_icons button[type="button"]{
    width: 6rem;
    height: 39px;
    background: var(--deepBlue-background);
    border: none;
    outline: none;
    border-radius: 25px;
    /* Text style */
    color: #fff;
    font-size: .72rem;
}
.active_head_bg{
    background-color: rgba(140, 139, 190, 0.65) !important;
    color: #495057 !important;
}
/* Import css variables */
@import "../../../variables.styles.css";

/* THE LEFT LAYOUT STYLE */
.signup-wrapper .signup-wrapper-child-1 .left{
    min-height: 100vh;
    background: var(--deepBlue-background);
}

.signup-wrapper .signup-wrapper-child-1 .left h1{
    /* width: 365px;
    height: 118px; */
    font-family: var(--font-family);
    font-style: normal;
    font-weight: bold;
    font-size: 1.8rem;
    line-height: 60px;
    font-feature-settings: 'liga' off;
    color: #FFFFFF;

}

.signup-wrapper .signup-wrapper-child-1 .left div{
   margin-left: 10%;
   margin-top: 16%;
}

.signup-wrapper .signup-wrapper-child-1 .left div img{
    /* width: 447px;
    height: 223.29px; */
    margin-top: 8%;
    margin-right: 2%;
}

    /* THE RIGHT LAYOUT STYLE */
.signup-wrapper .signup-wrapper-child-1 .right {
    padding-top: 6%;
}
.signup-wrapper .signup-wrapper-child-1 .right h1{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 60px;
    font-feature-settings: 'liga' off;
    color: #020079;
    margin-left: 8%;
    margin-top: 2rem;
}

.signup-wrapper .signup-wrapper-child-1 .right h6{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    font-feature-settings: 'liga' off, 'calt' off;
    color: rgba(2, 0, 121, 0.65);
    margin-left: 8%;
}
.signup-wrapper .signup-wrapper-child-1 .right .social-buttons{
    margin-top: -1%;
    margin-left: 8%;
}

.signup-wrapper .signup-wrapper-child-1 .right .social-buttons button[type="button"]{
    width: 228px;
    height: 55px;
    background: #FFFFFF;
    border: 1px solid #020079;
    box-sizing: border-box;
    border-radius: 30px;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #020079;
    margin-top: 5%;
}
.signup-wrapper .signup-wrapper-child-1 .right .social-buttons button[type="button"] img{
    margin-right: .5rem;
}
.signup-wrapper .signup-wrapper-child-1 .right .social-buttons button[type="button"]:nth-child(2){
    margin-left: 1rem;
}

.signup-wrapper .signup-wrapper-child-1 .right .line{
    display: flex;
    flex-flow: row wrap;
    margin-left: 8%;
    align-items: center;
    margin-top: 24px;
}
.signup-wrapper .signup-wrapper-child-1 .right .line hr{
    width: 234px;
    height: 1px;
}

/* THE FORM */
.signup-wrapper .signup-wrapper-child-1 .right form{
    margin-left: 8%;
}
.signup-wrapper .signup-wrapper-child-1 .right p{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.8);
}
.signup-wrapper .signup-wrapper-child-1 .right p{
    /* margin-left: 8%; */
    margin-top: 1rem;
}
.signup-wrapper .signup-wrapper-child-1 .right p span{
    color: #020079!important;
    text-decoration: underline;
}

/* MOBILE RESPONSIVENESS */
@media(max-width: 500px){
    .signup-wrapper .signup-wrapper-child-1 .left {
        display: none;
    }
    .signup-wrapper .signup-wrapper-child-1 .right
    .mobile-center,
    .signup-wrapper .signup-wrapper-child-1 .right
    h1,
    .signup-wrapper .signup-wrapper-child-1 .right
     h6{
        text-align: center;
        margin-left: 0;
    }
    .signup-wrapper .signup-wrapper-child-1 .right
    .social-buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .signup-wrapper .signup-wrapper-child-1 .right
    .social-buttons button[type="button"]:nth-child(2){
        margin-left: 0;
    }
    .signup-wrapper .signup-wrapper-child-1 .right
    .line {
        flex-direction: column;
        margin-left: 0;
    }
    .signup-wrapper .signup-wrapper-child-1 .right
     form {
         margin-left: 0;
     }
 }

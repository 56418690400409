/* Import css variables */
@import "../../../../../../variables.styles.css";

 .faq-image img {
    margin-left: 27%;
    margin-bottom: -1.8rem;
    cursor: pointer;
}
.question-result .question-result-wrapper{
    margin-left: 9%;
    margin-top: 1rem;
    font-family: var(--font-family);
}
.question-result .question-result-wrapper h6 span{
    font-size: 1.2rem;
    font-weight: 590;
}
.question-result .question-result-wrapper p{
    font-size: .9rem;
}
.question-result .question-result-wrapper p span {
    font-weight: 600;
}

.faq-line{
    max-width: 535px;
    height: 0px;    
    border: 1px solid rgba(0, 0, 0, 0.37);
    margin-left: 8.8%;
    margin-top: 5%;
}

.faq-button {
    margin-left: 9%;
}
.gigs-button button[type="button"],
.gigs-button button[type="submit"]{
    width: 7rem!important;
    height: 2.7rem!important;
    font-size: .85rem!important;
}
.faq-button button[type="button"]{
    width: 9rem!important;
    height: 48px!important;
    background: #FFFFFF;
    border: 1px solid #020079;
    box-sizing: border-box;
    border-radius: 25px;
}
.faq-button button[type="submit"]{
    width: 9rem!important;
    height: 48px!important;
    background: #020079;
    border-radius: 25px;
    margin-left: .5rem;
}

.text-icon-wrapper {
    display: flex;
    flex-flow: row wrap;
}
.text-icon-wrapper .edit-images {
    margin-left: .5rem;
}
.text-icon-wrapper .edit-images img{
    cursor: pointer;
}
.text-icon-wrapper .edit-images img:nth-child(2){
    margin-left: .3rem;
}
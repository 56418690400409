/* Import css variables */
@import "../../../variables.styles.css";


.gig_category h1 {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: bold;
    font-size: 2rem;
    line-height: 60px;
    font-feature-settings: 'liga' off;
    color: #020079;
    margin-top: 6.25rem;
    margin-left: 7%;
    text-transform: capitalize;
}

.gig_category .category_cardwrapper {
    width: 100%;
    display: grid;
    /* grid-template-columns: 22% 22% 22% 22%; */
    grid-template-columns: repeat(auto-fit, 18rem);
    justify-content: center;
    /* margin-left: 6.5%; */
    margin-top: 1.5rem;
}

.gig_category .category_cardwrapper .category_card {
    width: 90%;
    height: 359px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
    padding-left: 1rem;

}

.gig_category .category_cardwrapper .category_card h6 {
    font-style: normal;
    font-weight: 600;
    font-size: 1.2;
    line-height: 24px;
    font-feature-settings: 'liga' off, 'calt' off;
    color: #FFFFFF;
    margin-top: .97rem;
}

.gig_category .category_cardwrapper .category_card h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 1.7rem;
    line-height: 32px;
    color: #FFFFFF;
}

.gig_category .button_wrapper {
    display: flex;
    justify-content: flex-end;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;
}

.gig_category .button_wrapper .explore_but {
    width: 170px;
    height: 50px;
    background: var(--deepBlue-background);
    border-radius: 30px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: .88rem;
    line-height: 24px;
    color: #FFFFFF;
    border: none;
}

@media (max-width:600px){
    .gig_category .category_cardwrapper .category_card {
        margin-top: 20px;
    }
    .gig_category .button_wrapper {
        justify-content: center;
    }
}
.milestone-edit{
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 3rem 1rem;
}


.edit_milestone{
    display: flex;
    width: 100%;
    padding: 0.5rem;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0.5rem 0;
    align-items: flex-start;
}
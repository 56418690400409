/* Import css variables */
@import "../../../variables.styles.css";

footer {
    width: 1440px;
    height: auto;
    background: var(--deepBlue-background);
    padding: 4% 0 4% 0;
    /* margin-top: 10%; */
}

footer .foot-wrapper div h5{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 650;
    font-size: 18px;
    line-height: 20px;  
    color: #FFFFFF;
}

footer .foot-wrapper div p {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
    text-decoration: none!important;
}
footer .foot-wrapper div:nth-child(4) p{
    width: 208px;
    height: auto;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
}
footer .line {
    max-width: 1010px!important;
    height: 0px;
    border: 1px solid #FFFFFF;
    margin: 8% auto 0 auto;
}

/* Bottom */
footer .footer-bottom-wrapper .left-footer{
    display: flex;
    align-items: center;
    margin-right: 35.1%;
}
footer .footer-bottom-wrapper .left-footer img {
    width: 18px;
    height: 18px;
}
footer .footer-bottom-wrapper .left-footer p{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;    
    color: #FFFFFF;
    margin: .2rem 0 0 .5rem;
}

/* Right styles */
.right-footer {
    margin-top: 1.2rem;
}
.right-footer h6{
    font-family: var(--font-family);
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    margin-left: .8rem;
}
.right-footer .social-icons{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
}
.right-footer .social-icons img{
 cursor: pointer;
}
/* Import css variables */
@import '../../../../../variables.styles.css';

section .review-words {
  width: 90%;
  height: auto;
  margin-top: 1rem;
  padding: 1rem;
}
section .review-words .words {
  font-size: 0.95rem;
  margin-left: 6%;
}

section .review-words img {
  width: 2.5rem;
  height: 2.5rem;
}
section .review-words .image-copy section .review-words .words {
  margin-top: 0.8rem;
}
section .review-words .image-copy p[data='seller'] {
  font-weight: 590;
  margin-left: 0.8rem;
  margin-top: 0.5rem;
  /* font-family: var(--deepBlue-background); */
}
section .review-words .image-copy .card_title {
  margin-left: 0.7rem;
}
section .review-words .image-copy .card_title .card_title--wrapper img {
  width: 1.1rem;
  height: 1.1rem;
}
section .review-words .image-copy .card_title p[data='country'] {
  margin-top: -0.5rem !important;
  color: var(--deepBlue-background);
}
p {
  color: var(--deepBlue-background);
}
span {
  color: inherit;
}
section .review-words .year-pub {
  font-weight: 400;
  font-size: 0.8rem;
  color: var(--deepBlue-background);
  margin-left: 6%;
}
section .review-words .response {
  margin-left: 1.5rem;
  margin-top: 2rem;
}
section .review-words .response .words {
  margin-left: 3.1rem;
}
section .overview-cont .overview-wrapper .left-side .reviews_copy select {
  margin-right: 4.2rem;
  outline: none !important;
  font-size: 0.89rem;
}
section
  .overview-cont
  .overview-wrapper
  .left-side
  .reviews_copy
  .reviews_copy--left
  img {
  margin-left: 0.3rem;
}

@media (max-width: 600px){
  section .overview-cont .overview-wrapper .left-side .reviews_copy select {
    position: relative;
    margin-top: 60px;
    margin-left: -100px;
    width: 250px;
    height: 30px;
  }
  section .review-words{
    margin-bottom: 1rem;
  }
  section .review-words .words{
    font-size: 1.1rem;
  }
  section .review-words .image-copy .card_title p[data='country']{
    font-size: 1.3rem;
  }
  p{
    font-size: 1.3rem;
  }
}

.check_box{
    width: 5% !important;
    height: 12px !important;
}

.check_wrapper{
    font-size: 12px;
    margin-top: 1rem;
    display: flex;
    /* align-items: center; */
}


.form-agreement{
    border:  1px solid #fff;
    border-radius: 10px;
    height: 10rem;
    overflow-y: scroll;
    width: 100%;
    padding: 1rem;
    margin: 2rem 0;
    font-size: 1rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
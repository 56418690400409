.button-faq button[type="button"]{
 width: 8%!important;
 height: 34px!important;
}
section .add-form button[type="button"] {
    width: 86px !important;
    height: 30px;
    background: #020079;
    border-radius: 25px;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
    border: none;
}